import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react'

const CardFiltered = ({ modele }: any) => {

    const [IntervalPrix, setIntervalPrix] = useState([]) as any
    useEffect(() => {
        let current_date = moment();
        var prix_jour = _.filter(modele.categorie.Parametrage_prix, prix => moment(prix.date_debut)<= current_date && moment(prix.date_fin) >= current_date )
        prix_jour = prix_jour.length > 0 ? _.orderBy(prix_jour, ['nbr_jour'], ['asc']) : [];
        setIntervalPrix(prix_jour)
    }, [modele])

    return (
        <>
            <div className="well ad-listing clearfix">
                <div className="col-md-3 col-sm-5 col-xs-12 grid-style no-padding">
                    {/* <!-- Image Box --> */}
                    <div className="">
                        <img src={process.env.REACT_APP_API_PUBLIC_URL + 'modeles/' + modele.image[0]?.path} 
                             className="img-responsive" alt="" 
                             style={{ width: "100%", height: "auto" }} />
                        {/* <div className="total-images"><strong>8</strong> photos </div> */}

                    </div>
                    {/* <!-- Ad Status --> */}
                    <span className="ad-status"> Disponible </span>

                </div>
                <div className="col-md-9 col-sm-7 col-xs-12">
                    {/* <!-- Ad Content--> */}
                    <div className="row">
                        <div className="content-area">
                            <div className="col-md-9 col-sm-12 col-xs-12">
                                {/* <!-- Ad Title --> */}
                                <h3><a>{modele.libelle}</a></h3>
                                {/* <!-- Ad Meta Info --> */}
                                <ul className="ad-meta-info">
                                    <li> <i className="fa fa-map-marker"></i>
                                        Agence(s) : {modele.ModelAgence.map((agence: any) => (
                                            agence.agence.name + ' / '
                                        ))}
                                     </li>
                                    {/* <li>15 minutes ago </li> */}
                                </ul>
                                {/* <!-- Ad Description--> */}
                                <div className="ad-details">
                                    {/* <p>
                                        {modele.description}
                                    </p> */}

                                    <div className="short-info">
                                        <div className="ad-stats hidden-xs"><span>Marque : </span>{modele.marque.libelle}</div>
                                        <div className="ad-stats hidden-xs"><span>Carrosserie : </span>{modele.carrosserie.libelle}</div>
                                    </div>
                                    <div className="ad-listing content-area ad-details">
                                        <ul className="list-unstyled">
                                            <li><i className="flaticon-gas-station-1"></i>{modele.energie}</li>
                                            <li><i className="flaticon-air-conditioner-2"></i>{modele.climatiseur ? 'Oui': 'Non'}</li>
                                            <li><i className="flaticon-engine-2"></i>{modele.nbr_cv} CV</li>
                                            <li><i className="flaticon-car-door-1"></i>{modele.nbr_place} P</li>
                                            <li><i className="flaticon-gearshift"></i>{modele.boite_vitesse}</li>
                                            <li><i className="flaticon-car-2"></i>{modele.carrosserie.libelle}</li>
                                            <li><i className="flaticon-calendar-2"></i>Annee {modele.annee}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-xs-12 col-sm-12">
                                {/* <!-- Ad Stats --> */}

                                {/* <!-- Price --> */}

                                <div className="price text-center">
                                    {
                                        IntervalPrix.length==1 ? (<span>{IntervalPrix[0].prix_ttc} TND</span>) : ''
                                    } 
                                    {
                                        IntervalPrix.length > 1 ? (<span>{IntervalPrix[IntervalPrix.length - 1].prix_ttc} - {IntervalPrix[0].prix_ttc} TND</span>) : ''
                                    } 
                                </div>
                                <div className="clearfix"></div>
                                <br />
                                {/* <!-- Ad View Button --> */}
                                <a className="btn btn-block btn-primary" href={modele.url} target='_blank'>
                                    <i className="fa fa-list" aria-hidden="true"></i> Details
                                </a>
                                <br />
                                <a className="btn btn-block btn-success" href={`/cars/${modele.id}`} target='_blank'>
                                    <i className="fa fa-eye" aria-hidden="true"></i> Reserver
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Ad Content End --> */}
                </div>
            </div>
        </>
    )
}

export default CardFiltered