import React, { useEffect } from 'react'
import IntlTelInput, { CountryData } from 'react-intl-tel-input-18';
import 'react-intl-tel-input-18/dist/main.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { RegisterSchema } from '../../Schemas/RegisterSchema';
import { RegisterDataType } from '../../Types/RegisterDataType';
import { useAppDispatch } from '../../_redux/hooks';
import { registerApi } from '../../_redux/Slices/auth/authSlice';
import { ToastSuccess } from '../Toasts/ToastSuccess';
import { useNavigate } from 'react-router-dom';


const RegisterForm = () => {

    const navigate = useNavigate();
    const formRegister = { resolver: yupResolver(RegisterSchema) };
    const { register, handleSubmit, reset, control, setValue, formState } = useForm<RegisterDataType>(formRegister);
    const { errors } = formState;

    const dispatch = useAppDispatch();
    useEffect(() => {
        reset({
            nom: '',
            prenom: '',
            mobile: {},
            email: '',
            password: '',
            confirm_password: '',
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handlePhoneChange = (status: boolean, phoneNumber: string, country: CountryData) => {
        setValue('mobile', {
            status,
            phoneNumber,
            country
        })
    };

    const onSubmit = async (data: RegisterDataType) => {
        const rsData = await dispatch(registerApi(data))
        console.log(rsData.payload)
        if (rsData.payload.success) {
            ToastSuccess(rsData.payload.message)
            navigate('/')
        }
    }


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>

                {/* <a className="btn btn-lg btn-block btn-social btn-facebook">
              <span className="fa fa-facebook"></span> Sign up with Facebook
            </a>

            <a className="btn btn-lg btn-block btn-social btn-google">
              <span className="fa fa-google"></span> Sign up with Facebook
            </a> */}

                <h2 className="no-span"><b>(Inscription)</b></h2>
                <div className="row">
                    <div className="col-md-6 col-xs-12">
                        <div className="form-group">
                            <label>Nom</label>
                            <input type="text" placeholder="Nom"
                                className={`form-control ${errors.nom ? 'is-invalid' : ''}`}
                                {...register('nom')} />
                            <div className="invalid-feedback">{errors.nom?.message}</div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <div className="form-group">
                            <label>Prénom</label>
                            <input type="text" placeholder="Prénom"
                                className={`form-control ${errors.prenom ? 'is-invalid' : ''}`}
                                {...register('prenom')} />
                            <div className="invalid-feedback">{errors.prenom?.message}</div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label>Mobile</label>
                    <Controller
                        control={control}
                        name="mobile"
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <IntlTelInput
                                containerClassName="intl-tel-input"
                                inputClassName={`form-control ${errors.mobile?.status ? 'is-invalid' : ''}`}
                                style={{ width: '100%' }}
                                onPhoneNumberChange={handlePhoneChange}
                                defaultCountry='tn'
                                preferredCountries={['tn', 'fr', 'ma', 'dz']}
                            />
                        )}
                    />
                    <div className="invalid-feedback">{errors.mobile?.status?.message}</div>


                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input placeholder="Email"
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`} type="email"
                        {...register('email')} />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Mot de passe</label>
                            <input placeholder="Mot de Passe"
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`} type="password"
                                {...register('password')} />
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Confirmer Mot de passe</label>
                            <input placeholder="Confirmer Mot de passe"
                                className={`form-control ${errors.confirm_password ? 'is-invalid' : ''}`} type="password"
                                {...register('confirm_password')} />
                            <div className="invalid-feedback">{errors.confirm_password?.message}</div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-xs-12 col-sm-7">
                            <div className="skin-minimal">
                                {/* <ul className="list">
                                    <li>
                                        <input type="checkbox" id="minimal-checkbox-1" />
                                        <label htmlFor="minimal-checkbox-1">i agree 
                                            <a href="#">Terms of Services</a>
                                        </label>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                        {/* <div className="col-xs-12 col-sm-5 text-right">
                            <p className="help-block"><a data-target="#myModal" data-toggle="modal">Forgot password?</a>
                            </p>
                        </div> */}
                    </div>
                </div>
                <button className="btn btn-theme btn-lg btn-block">Register</button>

            </form>
        </>
    )
}

export default RegisterForm