import React from 'react'
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb'
 import ResetPasswordForm from '../../Components/Auth/ResetPasswordForm'

const ResetPasswordAndActivateAccountPage = () => {
  return (
    <>
      {/* <!-- =-=-=-=-=-=-= Breadcrumb =-=-=-=-=-=-= --> */}
      <Breadcrumb linkName="Acceuil"
        linkUrl="/"
        subLinkName="Sécurite"
        subLinkUrl='#'
        title='Créer votre mot de passe' />
      
      {/* <!-- =-=-=-=-=-=-= Breadcrumb End =-=-=-=-=-=-= --> */}

      {/* <!-- =-=-=-=-=-=-= Main Content Area =-=-=-=-=-=-= --> */}
      <div className="main-content-area clearfix">

        {/* <!-- =-=-=-=-=-=-= Login form =-=-=-=-=-=-= --> */}
        <section className="section-padding no-top gray">
          {/* <!-- Main Container --> */}
          <div className="container">
            {/* <!-- Row --> */}
            <div className="row">
              {/* <!-- Middle Content Area --> */}
              <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                {/* <!--  Form --> */}
                <div className="form-grid">
                    <ResetPasswordForm />
                </div>
                {/* <!-- Form --> */}
              </div>
              {/* <!-- Middle Content Area  End --> */}
            </div>
            {/* <!-- Row End --> */}
          </div>
          {/* <!-- Main Container End --> */}
        </section>
        {/* <!-- =-=-=-=-=-=-= Login Page End =-=-=-=-=-=-= --> */}

      </div>
    </>
  )
}

export default ResetPasswordAndActivateAccountPage