import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from "../../store";
import axios from 'axios';
import OptionType from '../../../Types/OptionType';
import { CarburantType } from '../../../Types/CarburantType';
import _ from 'lodash';

/**
 * Get list of options .
 * @public
 */
export const findOptionsApi = createAsyncThunk('modeles/findAllOptionsApi', async (data: null,{ rejectWithValue, dispatch }) => {

    try {
        const response = await axios.get(`options`);
        return response.data;
    } catch (error: any) {
        console.log(error)
         return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});




const initialState :OptionType[] = []

export const optionSlice = createSlice({
    name: 'options',
    initialState: initialState,
    reducers: {
        setPrixCarburantOnOption: (state, action) => {
            const carburant : CarburantType = action.payload;

            const updated_option = action.payload.data;
            var index = _.findIndex(state, { type_option: "CARBURANT" });

            const option = state[index]
            // Replace item at index using native splice
            state.splice(index, 1, {
                ...option, 
                prix_ttc: carburant.prix_ttc, 
                prix_ht: carburant.prix_ht, 
                tva: carburant.tva
            });
        }
    },
    extraReducers: builder => {
        builder.addCase(findOptionsApi.fulfilled, (state , action) => {
            return state = action.payload;
        });
        
    }
})

export const { setPrixCarburantOnOption } = optionSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const ListOptions = (state: RootState) => state.options;

export default optionSlice.reducer;