import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../_redux/hooks';
import jwt_decode from 'jwt-decode'
import { getToken, isLoggedIn, logoutApi } from '../../_redux/Slices/auth/authSlice';
import customJwtPayload from '../../Types/customJwtPayload';


const TopBar = () => {


    const [user, setUser] = useState({} as any)
    const token: string = useAppSelector(getToken) as string
    const loggedIn: boolean = useAppSelector(isLoggedIn)
    const dispatch = useAppDispatch();
    /**
     * decode User token
     */
    useEffect(() => {
        if (loggedIn) {
            const decodedToken = jwt_decode<customJwtPayload>(token)
            setUser(decodedToken.user)
        }

    }, [loggedIn])


    const logout = () => {
        dispatch(logoutApi(null))
    }



    return (

        <div className="header-top dark">
            <div className="container">
                <div className="row">
                    {/* <!-- Header Top Left --> */}
                    <div className="header-top-left col-md-6 col-sm-6 col-xzs-12 hidden-xs">
                        <ul className="listnone">

                            <li>
                                <Link to="/cars"><i className="fa fa-heart-o" aria-hidden="true"></i> A props</Link>
                            </li>
                            <li><a href="#"><i className="fa fa-folder-open-o" aria-hidden="true"></i> FAQS</a></li>
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-globe" aria-hidden="true"></i> Langue
                                    <span className="caret"></span>
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a href="#">Anglais</a></li>
                                    <li><a href="#">Arabe</a></li>
                                    <li><a href="#">Francais</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- Header Top Right Social --> */}
                    <div className="header-right col-md-6 col-sm-6 col-xs-12 ">
                        <div className="pull-right">
                            <ul className="listnone">
                                {
                                    !loggedIn &&
                                    (
                                        <>
                                            <li><a href="/login"><i className="fa fa-sign-in"></i> Log in</a></li>
                                            {/* className="hidden-xs hidden-sm" */}
                                            <li ><a href="/register"><i className="fa fa-unlock" aria-hidden="true"></i> Register</a></li>
                                        </>
                                    )
                                }

                                {
                                    loggedIn && (
                                        <li className="dropdown">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                                <img className="img-circle resize" alt="" src="/assets/images/users/3.jpg" />
                                                <span className="myname"> {user.prenom} {user.nom}</span> <span className="caret"></span>
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li><a href="#">Mon Profile</a></li>
                                                <li><a href="#">Mes reservations</a></li>
                                                <li><a onClick={e => logout()}>Déconnection</a></li>
                                                {/* <li><a href="#">Active Ads</a></li>
                                                <li><a href="#">Favourite Ads</a></li>
                                                <li><a href="#">Message Panel</a></li>
                                                <li><a href="#">Account Deactivation</a></li> */}
                                            </ul>
                                        </li>
                                    )
                                }
                                {
                                   loggedIn && (<li><a onClick={e => logout()}>Déconnection</a></li>) 
                                }
                                

                                <li>
                                    <a href="/" className="btn btn-theme">Allo Rent</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopBar