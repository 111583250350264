import React, { useState } from 'react'
import { ToastSuccess } from '../Toasts/ToastSuccess'
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../_redux/hooks';
import { useForm } from 'react-hook-form';
import { LoginDataType } from '../../Types/LoginDataType';
import { LoginSchema } from '../../Schemas/LoginSchema';
import { loginApi, resendEmailVerificationApi } from '../../_redux/Slices/auth/authSlice';
import { ToastWarning } from '../Toasts/ToastWarning';



const LoginForm = () => {

    const navigate = useNavigate();
    const formOptions = { resolver: yupResolver(LoginSchema) };
    const { register, handleSubmit, formState } = useForm<LoginDataType>(formOptions);
    const { errors } = formState;

    const [Message, setMessage] = useState('');
    const [AlertType, setAlertType] = useState('alert-success');
    const [action, setAction] = useState('login');

    const [Payload, setPayload] = useState({ email_id: '', token_verification: '' });

    const dispatch = useAppDispatch();

    /**
     * 
     * @param data LoginDataType<email: string, password: string>
     */
    const onSubmit = async (data: LoginDataType) => {

        setAction('')
        const response = await dispatch(loginApi(data))
        setMessage(response.payload.message)
        if (response.payload.success) {
            ToastSuccess(response.payload.message)
            navigate('/')
        } else {
            setAlertType('alert-danger')
            /**
             * cas d'un email pas encore valider
             */
            ToastWarning(response.payload.message);
            if (response.payload.action == "redirectToActivatePage") {
                setAction("redirectToActivatePage")
                setPayload(response.payload.data)
 
            }
        }
    }

    const messageActivationAccount = (email_id: string, token_verification: string) => {
        console.log(Payload)
        return (
            <>
                <a href="#" onClick={e => resendEmailVerification(email_id, token_verification)} style={{ color:'blue',textDecoration: "underline" }}> Cliquer ici </a> Pour ré-envoyer un email de validation
            </>
        )
    }

    const resendEmailVerification = async (email_id: string, token_verification: string) => {
        const data = { email_id, token_verification }
        const response = await dispatch(resendEmailVerificationApi(data))
        setAction('')
        if (response.payload.success) {
            setAlertType('alert-success')
            ToastSuccess(response.payload.message)
        } else {
            setAlertType('alert-danger')
            ToastWarning(response.payload.message)
        }
        setMessage(response.payload.message);
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>

                {/* <a className="btn btn-lg btn-block btn-social btn-facebook">
                    <span className="fa fa-facebook"></span> Sign in with Facebook
                </a>

                <a className="btn btn-lg btn-block btn-social btn-google">
                    <span className="fa fa-google"></span> Sign in with Facebook
                </a> */}

                <h2 className="no-span"><b>(Login)</b></h2>

                {
                    Message && (
                        <>
                            <div className={"alert " + AlertType} role="alert">
                                {Message}

                                {
                                    action === 'redirectToActivatePage' && messageActivationAccount(Payload.email_id, Payload.token_verification)
                                }
                            </div>




                        </>
                    )
                }
                <div className="form-group">
                    <label>Email</label>
                    <input placeholder="Email" type="email"
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        {...register('email')} />
                    <div className="invalid-feedback">{errors.email?.message}</div>

                </div>
                <div className="form-group">
                    <label>Mot de passe</label>
                    <input placeholder="Mot de passe" type="password"
                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                        {...register('password')} />
                    <div className="invalid-feedback">{errors.password?.message}</div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="skin-minimal">
                                <ul className="list">
                                    <li>
                                        <input type="checkbox" id="minimal-checkbox-1" />
                                        <label htmlFor="minimal-checkbox-1">Se souvenir de moi</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="btn btn-theme btn-lg btn-block">Login With Us</button>
            </form>
        </>
    )
}

export default LoginForm