import React from 'react'

const CarsPagination = () => {
    return (
        <> 
            <div className="text-center margin-top-30">
                <ul className="pagination ">
                    <li><a href="#"><i className="fa fa-chevron-left"></i></a></li>
                    <li><a href="#">1</a></li>
                    <li className="active"><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#"><i className="fa fa-chevron-right"></i></a></li>
                </ul>
            </div>
        </>
    )
}

export default CarsPagination