import { object, string , bool, ref} from 'yup';
// import { array, number, ref, object, string, mixed, BooleanSchema, boolean, bool } from 'yup';


export const ResetPasswordSchema = object({
    password: string().min(6, 'Il faut entrer au moins 6 caractères.').required('Mot de passe Obligatoire!!'),
    confirm_password: string()
       .oneOf([ref('password')], 'Les mots de passe doivent être identiques!!')
  });

