import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from "../../store";
import _ from 'lodash';
import axios from 'axios';
import ReservationTemp from '../../../Types/ReservationTemp';
import { ReservationType } from '../../../Types/Entities/ReservationType';


/**
 * Get list of reservation for client .
 * @Private
 */
export const MesReservationsApi = createAsyncThunk('reservations/MesReservationsApi', async (data: null, { rejectWithValue, dispatch }) => {

    try {
        const response = await axios.get(`reservations/MesReservationsApi`);
        return response.data;
    } catch (error: any) {
        console.log(error)
        return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});



/**
 * Get  reservation for client by id .
 * @Private
 */
export const ReservationByIdApi = createAsyncThunk('reservations/ReservationByIdApi', async (id: number, { rejectWithValue, dispatch }) => {

    try {
        const response = await axios.get(`reservations/ReservationByIdApi`, {params: {id: id}});
        return response.data;
    } catch (error: any) {
        console.log(error)
        return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});

/**
 * @route /api/reservations/addReservation
 * @param {any} data
 * @public
 */
export const addReservationApi = createAsyncThunk('reservations/addReservation', async (data: any, { rejectWithValue, dispatch }) => {

    // console.log('addReservation body', data)
    try {
        const response = await axios.post(`reservations/addReservation`, data);
        return response.data;
    } catch (error: any) {
        console.log(error)
        return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});






const initialState: {
    temp_reservation: ReservationTemp,
    reservations: [],
    reservation: ReservationType| null
} = {
    temp_reservation: {
        total_options: {
            total_ht: 0,
            total_ttc: 0
        },
        total_location: {
            total_ht: 0,
            total_ttc: 0
        },
        numberOfDays: 1,
        listSelectedOptions: {},
        list_prix_par_jour : {},
        dates: [],
    },
    reservations: [],
    reservation: null
}

export const reservationSlice = createSlice({
    name: 'reservation',
    initialState: initialState,
    reducers: {
        changeTotalOptions: (state, action) => {
            state.temp_reservation.total_options.total_ht = action.payload.total_ht
            state.temp_reservation.total_options.total_ttc = action.payload.total_ttc
        },
        changeTotalLocation: (state, action) => {
            state.temp_reservation.total_location.total_ht = action.payload.total_ht
            state.temp_reservation.total_location.total_ttc = action.payload.total_ttc
        },
        changeNumberOfDays: (state, action) => {
            state.temp_reservation.numberOfDays = action.payload
        },
        changeListSelectedOptions: (state, action) => {
            //state.temp_reservation.listSelectedOptions = _.filter(action.payload, option => option.nbr_selected > 0);
            state.temp_reservation.listSelectedOptions = action.payload;
        }, 
        changeDates: (state, action) => {
            state.temp_reservation.dates = action.payload
        },
        changeListPrixParJour : (state, action) => {
            state.temp_reservation.list_prix_par_jour = action.payload
        }

    },
    extraReducers: builder => {
        builder.addCase(MesReservationsApi.fulfilled, (state, action) => {
            state.reservations = action.payload;
        });

        builder.addCase(ReservationByIdApi.fulfilled, (state, action) => {
            state.reservation = action.payload;
        });

        builder.addCase(addReservationApi.fulfilled, (state, action) => {
            state.temp_reservation = {
                total_options: {
                    total_ht: 0,
                    total_ttc: 0
                },
                total_location: {
                    total_ht: 0,
                    total_ttc: 0
                },
                numberOfDays: 1,
                listSelectedOptions: {},
                list_prix_par_jour : {},
                dates: [],
            };
        });
    }
})

export const { changeTotalOptions, changeNumberOfDays, changeListSelectedOptions, changeDates, changeListPrixParJour , changeTotalLocation} = reservationSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const tempReservation = (state: RootState) => state.reservations.temp_reservation;
export const ListReservations = (state: RootState) => state.reservations.reservations;
export const Reservation = (state: RootState) => state.reservations.reservation;

export default reservationSlice.reducer;