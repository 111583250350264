import React from 'react'

const RecentCarsPanel = () => {
    return (
        <>
            <div className="panel panel-default">
                {/* <!-- Heading --> */}
                <div className="panel-heading" >
                    <h4 className="panel-title">
                        <a>
                            Recent Ads
                        </a>
                    </h4>
                </div>
                {/* <!-- Content --> */}
                <div className="panel-collapse">
                    <div className="panel-body recent-ads">
                        {/* <!-- Ads --> */}
                        <div className="recent-ads-list">
                            <div className="recent-ads-container">
                                <div className="recent-ads-list-image">
                                    <a href="#" className="recent-ads-list-image-inner">
                                        <img src="assets/images/posting/thumb-1.jpg" alt="" />
                                    </a>
                                    {/* <!-- /.recent-ads-list-image-inner --> */}
                                </div>
                                {/* <!-- /.recent-ads-list-image --> */}
                                <div className="recent-ads-list-content">
                                    <h3 className="recent-ads-list-title">
                                        <a href="#">Audi Q5 2.0T quattro Premium </a>
                                    </h3>
                                    <ul className="recent-ads-list-location">
                                        <li><a href="#">New York</a>,</li>
                                        <li><a href="#">Brooklyn</a></li>
                                    </ul>
                                    <div className="recent-ads-list-price">
                                        $ 17,000
                                    </div>
                                    {/* <!-- /.recent-ads-list-price --> */}
                                </div>
                                {/* <!-- /.recent-ads-list-content --> */}
                            </div>
                            {/* <!-- /.recent-ads-container --> */}
                        </div>
                        {/* <!-- Ads --> */}
                        <div className="recent-ads-list">
                            <div className="recent-ads-container">
                                <div className="recent-ads-list-image">
                                    <a href="#" className="recent-ads-list-image-inner">
                                        <img src="assets/images/posting/thumb-2.jpg" alt="" />
                                    </a>
                                    {/* <!-- /.recent-ads-list-image-inner --> */}
                                </div>
                                {/* <!-- /.recent-ads-list-image --> */}
                                <div className="recent-ads-list-content">
                                    <h3 className="recent-ads-list-title">
                                        <a href="#">Honda Civic 2017 Sports Edition</a>
                                    </h3>
                                    <ul className="recent-ads-list-location">
                                        <li><a href="#">New York</a>,</li>
                                        <li><a href="#">Brooklyn</a></li>
                                    </ul>
                                    <div className="recent-ads-list-price">
                                        $ 66,000
                                    </div>
                                    {/* <!-- /.recent-ads-list-price --> */}
                                </div>
                                {/* <!-- /.recent-ads-list-content --> */}
                            </div>
                            {/* <!-- /.recent-ads-container --> */}
                        </div>
                        {/* <!-- Ads --> */}
                        <div className="recent-ads-list">
                            <div className="recent-ads-container">
                                <div className="recent-ads-list-image">
                                    <a href="#" className="recent-ads-list-image-inner">
                                        <img src="assets/images/posting/thumb-3.jpg" alt="" />
                                    </a>
                                    {/* <!-- /.recent-ads-list-image-inner --> */}
                                </div>
                                {/* <!-- /.recent-ads-list-image --> */}
                                <div className="recent-ads-list-content">
                                    <h3 className="recent-ads-list-title">
                                        <a href="#">2014 Ford Shelby GT500 Coupe</a>
                                    </h3>
                                    <ul className="recent-ads-list-location">
                                        <li><a href="#">New York</a>,</li>
                                        <li><a href="#">Brooklyn</a></li>
                                    </ul>
                                    <div className="recent-ads-list-price">
                                        $ 37,000
                                    </div>
                                    {/* <!-- /.recent-ads-list-price --> */}
                                </div>
                                {/* <!-- /.recent-ads-list-content --> */}
                            </div>
                            {/* <!-- /.recent-ads-container --> */}
                        </div>
                        {/* <!-- Ads --> */}
                        <div className="recent-ads-list">
                            <div className="recent-ads-container">
                                <div className="recent-ads-list-image">
                                    <a href="#" className="recent-ads-list-image-inner">
                                        <img src="assets/images/posting/thumb-4.jpg" alt="" />
                                    </a>
                                    {/* <!-- /.recent-ads-list-image-inner --> */}
                                </div>
                                {/* <!-- /.recent-ads-list-image --> */}
                                <div className="recent-ads-list-content">
                                    <h3 className="recent-ads-list-title">
                                        <a href="#">Lamborghini Gallardo 5.0 V10 2dr</a>
                                    </h3>
                                    <ul className="recent-ads-list-location">
                                        <li><a href="#">New York</a>,</li>
                                        <li><a href="#">Brooklyn</a></li>
                                    </ul>
                                    <div className="recent-ads-list-price">
                                        $ 11,000
                                    </div>
                                    {/* <!-- /.recent-ads-list-price --> */}
                                </div>
                                {/* <!-- /.recent-ads-list-content --> */}
                            </div>
                            {/* <!-- /.recent-ads-container --> */}
                        </div>
                        {/* <!-- Ads --> */}
                        <div className="recent-ads-list">
                            <div className="recent-ads-container">
                                <div className="recent-ads-list-image">
                                    <a href="#" className="recent-ads-list-image-inner">
                                        <img src="assets/images/posting/thumb-5.jpg" alt="" />
                                    </a>
                                    {/* <!-- /.recent-ads-list-image-inner --> */}
                                </div>
                                {/* <!-- /.recent-ads-list-image --> */}
                                <div className="recent-ads-list-content">
                                    <h3 className="recent-ads-list-title">
                                        <a href="#">Porsche 911 Carrera 2017 </a>
                                    </h3>
                                    <ul className="recent-ads-list-location">
                                        <li><a href="#">New York</a>,</li>
                                        <li><a href="#">Brooklyn</a></li>
                                    </ul>
                                    <div className="recent-ads-list-price">
                                        $ 20,000
                                    </div>
                                    {/* <!-- /.recent-ads-list-price --> */}
                                </div>
                                {/* <!-- /.recent-ads-list-content --> */}
                            </div>
                            {/* <!-- /.recent-ads-container --> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecentCarsPanel