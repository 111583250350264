import React from 'react'

const TransmissionPanel = () => {
    return (
        <>
            <div className="panel panel-default">
                {/* <!-- Heading --> */}
                <div className="panel-heading" role="tab" id="heading9">
                    <h4 className="panel-title">
                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse9" aria-expanded="false" aria-controls="collapse9">
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Transmission
                        </a>
                    </h4>
                </div>
                {/* <!-- Content --> */}
                <div id="collapse9" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading9">
                    <div className="panel-body">
                        <div className="skin-minimal">
                            <ul className="list">
                                <li>
                                    <input tabIndex={7} type="radio" id="automatic" name="minimal-radio" />
                                    <label htmlFor="automatic">Automatic</label>
                                </li>
                                <li>
                                    <input tabIndex={7} type="radio" id="manual" name="minimal-radio" />
                                    <label htmlFor="manual">Manual</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransmissionPanel