import React from 'react'
import BrandsPanel from '../Widgets/BrandsPanel'
import TransmissionPanel from '../Widgets/TransmissionPanel'
import ConditionPanel from '../Widgets/ConditionPanel'
import BodyTypePanel from '../Widgets/BodyTypePanel'
import PricePanel from '../Widgets/PricePanel'
import YearPanel from '../Widgets/YearPanel'
import RecentCarsPanel from '../Widgets/RecentCarsPanel'
import SortingFilter from '../Widgets/SortingFilter'
import CardCars from '../Cards/CardCars'
import CarsPagination from '../Pagination/CarsPagination'

const CarsListSection = () => {
  return (
    <>
      {/* <!-- =-=-=-=-=-=-= Latest Ads =-=-=-=-=-=-= --> */}
      <section className="section-padding no-top gray">
        {/* <!-- Main Container --> */}
        <div className="container">
          {/* <!-- Row --> */}
          <div className="row">
            {/* <!-- Middle Content Area --> */}
            <div className="col-md-8 col-md-push-4 col-lg-8 col-sx-12">
              {/* <!-- Row --> */}
              <div className="row">
                {/* <!-- Sorting Filters --> */}
                <SortingFilter />
                {/* <!-- Sorting Filters End--> */}
                {/* <!-- Ads Archive --> */}
                <div className="col-md-12 col-xs-12 col-xs-12">
                  <div className="posts-masonry">

                    <CardCars />
                    <CardCars />
                    <CardCars />
                    <CardCars />
                    <CardCars />

                  </div>
                  {/* <!-- Ads Archive End -->   */}
                </div>
                <div className="clearfix"></div>
                {/* <!-- Pagination -->   */}
                <CarsPagination />
                {/* <!-- Pagination End -->    */}
              </div>
              {/* <!-- Row End --> */}
            </div>
            {/* <!-- Middle Content Area  End --> */}
            {/* <!-- Left Sidebar --> */}
            <div className="col-md-4 col-md-pull-8 col-sx-12">
              {/* <!-- Sidebar Widgets --> */}
              <div className="sidebar">
                {/* <!-- Panel group --> */}
                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                  {/* <!-- Brands Panel -->     */}
                  <BrandsPanel />
                  {/* <!-- Brands Panel End --> */}
                  {/* <!-- Condition Panel -->     */}
                  <TransmissionPanel />
                  {/* <!-- Condition Panel End -->   */}
                  {/* <!-- Condition Panel -->     */}
                  <ConditionPanel />
                  {/* <!-- Condition Panel End -->   */}
                  {/* <!-- Body Type Panel -->     */}
                  <BodyTypePanel />
                  {/* <!-- Condition Panel End -->   */}
                  {/* <!-- Pricing Panel --> */}
                  <PricePanel />
                  {/* <!-- Pricing Panel End --> */}
                  {/* <!-- Year Panel -->     */}
                  <YearPanel />
                  {/* <!-- Year Panel End -->   */}
                  {/* <!-- Latest Ads Panel --> */}
                  <RecentCarsPanel />
                  {/* <!-- Latest Ads Panel End --> */}
                </div>
                {/* <!-- panel-group end --> */}
              </div>
              {/* <!-- Sidebar Widgets End --> */}
            </div>
            {/* <!-- Left Sidebar End --> */}
          </div>
          {/* <!-- Row End --> */}
        </div>
        {/* <!-- Main Container End --> */}
      </section>
      {/* <!-- =-=-=-=-=-=-= Car list section End =-=-=-=-=-=-= --> */}
    </>
  )
}

export default CarsListSection