import React from "react";
import { Modal } from "react-bootstrap";
import Modele from "../../../Types/Modele";
import OptionType from "../../../Types/OptionType";
import { CarburantType } from "../../../Types/CarburantType";

const ReservationCarburant = ({
  ShowModal,
  SetShowModal,
  modele,
  carburant,
  options
}: {
  ShowModal: boolean;
  SetShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  modele: Modele;
  carburant:CarburantType[];
  options:OptionType[];
}) => {

  const reservoir = modele.reservoir;
  const energie = modele.energie;
  const selectedCarburant = carburant[0];

  const option_carburan: OptionType[] | undefined = options && options.length ? options.filter(option => option.type_option=="CARBURAN") : []


  return (
    <Modal show={ShowModal} onHide={() => SetShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          Détails Prix Carburant
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <h3 className="text-center c-black">{energie}</h3>
            <hr />
          </div>
          <div className="col-md-6 col-xs-12 col-sm-6">
            <h4 className="text-center c-black">Réservoir : {reservoir} Litres</h4>
            <hr />
          </div>
          <div className="col-md-6 col-xs-12 col-sm-6">
            <h4 className="text-center c-black">Prix TTC/Litre : {selectedCarburant?.prix_ttc} TND</h4>
            <hr />
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">Réservoir</th>
              <th className="text-center"> Nombre de Litre</th>
              <th className="text-center"> Total TTC</th>
            </tr>
          </thead>
          <tbody>
            {
              option_carburan && option_carburan.length && Array.from({length: option_carburan[0].nbr_max}, (_, i) => (
                <tr key={i}>
                  <td className="text-center">
                    {i+1}/ {option_carburan[0].nbr_max}
                  </td>
                  <td className="text-center">{ reservoir ? (reservoir / option_carburan[0].nbr_max) * (i+1) : 0} L</td>
                  <td className="text-center">
                   { reservoir && selectedCarburant ? (reservoir / option_carburan[0].nbr_max) * (i+1) * selectedCarburant.prix_ttc : 0} TND
                  </td>
                </tr>
              ))
            }
           
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ReservationCarburant;
