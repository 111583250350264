import React from 'react'

const BodyTypePanel = () => {
    return (
        <>
            <div className="panel panel-default">
                {/* <!-- Heading --> */}
                <div className="panel-heading" role="tab" id="heading7">
                    <h4 className="panel-title">
                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse7" aria-expanded="false" aria-controls="collapse7">
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Body Type
                        </a>
                    </h4>
                </div>
                {/* <!-- Content --> */}
                <div id="collapse7" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading7">
                    <div className="panel-body">
                        <div className="skin-minimal">
                            <ul className="list">
                                <li>
                                    <input type="radio" id="c1" name="minimal-radio" />
                                    <label htmlFor="c1">Sedan </label>
                                </li>
                                <li>
                                    <input type="radio" id="c2" name="minimal-radio" />
                                    <label htmlFor="c2">SUV</label>
                                </li>
                                <li>
                                    <input type="radio" id="c3" name="minimal-radio" />
                                    <label htmlFor="c3">Crossover</label>
                                </li>
                                <li>
                                    <input type="radio" id="c4" name="minimal-radio" />
                                    <label htmlFor="c4">Luxury</label>
                                </li>
                                <li>
                                    <input type="radio" id="c5" name="minimal-radio" />
                                    <label htmlFor="c5">Truck</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BodyTypePanel