import React from 'react'

interface  Options {
    linkName : string,
    linkUrl : string,
    subLinkName : string,
    subLinkUrl : string,
    title : string
}
const Breadcrumb = (options: Options) => {
    return (
        <>
            {/* <!-- =-=-=-=-=-=-= Breadcrumb =-=-=-=-=-=-= --> */}
            <div className="page-header-area-2 gray" style={{ padding: "5px 0px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="small-breadcrumb">
                                <div className=" breadcrumb-link">
                                    <ul>
                                        <li><a href={options.linkUrl}>{options.linkName}</a></li>
                                        <li><a className="active" href={options.subLinkUrl}>{options.subLinkName}</a></li>
                                    </ul>
                                </div>
                                <div className="header-page">
                                    <h1>{options.title}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- =-=-=-=-=-=-= Breadcrumb End =-=-=-=-=-=-= --> */}
        </>
    )
}

export default Breadcrumb