import { object, string, bool, ref, date, boolean } from 'yup';
// import { array, number, ref, object, string, mixed, BooleanSchema, boolean, bool } from 'yup';


export const ReservationSchema = object({

  date_debut: date().required('Date Début Obligatoire!!'),
  date_fin: date().required('Date Fin Obligatoire!!'),
  heure_debut: date().required('Heure Début Obligatoire!!'),
  heure_fin: date().required('Heure Fin Obligatoire!!'),
  date_permis: date().required('date permis Obligatoire!!'),
  civilite: string().required('Civilité Obligatoire!!'),
  nom: string().required('Nom Obligatoire!!'),
  prenom: string().required('Prenom Obligatoire!!'),
  mobile: object().shape({
    status: bool().required('Mobile Obligatoire!!').oneOf([true], 'Veuillez bien saisir un Numéro valide')
  }),
  email: string().email().required("Veuillez bien saisir un email valide"),
  age: string().required('Age Obligatoire!!'),
  adresse: string().optional(),
  remarque: string().optional(),
  vol: boolean(),
  numero_vol: string().when("vol", {
    is: true,
    then(schema) {
      return schema.required('Numero de vol Obligatoire!!');
    },
  }),
  condition: bool().required('Condition Obligatoire!!').oneOf([true], "Veuillez bien accepter les conditions d'utilisation")
});

