import React from 'react'

const PricePanel = () => {
    return (
        <>
            <div className="panel panel-default">
                {/* <!-- Heading --> */}
                <div className="panel-heading" role="tab" id="headingfour">
                    <h4 className="panel-title">
                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Price
                        </a>
                    </h4>
                </div>
                {/* <!-- Content --> */}
                {/* <div id="collapsefour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfour">
                                 <div className="panel-body">
                                    <span className="price-slider-value">Price ($) <span id="price-min"></span> - <span id="price-max"></span></span>
                                    <div id="price-slider"></div>
                                    <a className="btn btn-theme btn-sm margin-top-20">Search</a>
                                 </div>
                              </div> */}
            </div>
        </>
    )
}

export default PricePanel