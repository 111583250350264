import React from "react";
import { Modal } from "react-bootstrap";
import { useAppSelector } from "../../../_redux/hooks";
import { tempReservation } from "../../../_redux/Slices/reservations/reservationSlice";
import { dateFormatStr, timeFormatStr } from "../../../Helpers/Helpers";
import { tr } from "date-fns/locale";
import _ from "lodash";

const ReservationDetails = ({
  ShowModal,
  SetShowModal
}: {
  ShowModal: boolean;
  SetShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {

  const temp_reservation = useAppSelector(tempReservation)


  return (
    <Modal show={ShowModal} onHide={() => SetShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title className="c-black">
          Détails Reservation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Options */}
      <div className="row">
          <div className="col-md-6 aliceblue">
            <h5 className="text-center"> <b>Nombre de Jours :  <br /><span className="c-blue">{temp_reservation.numberOfDays}</span></b></h5>
          </div>
          <div className="col-md-6 aliceblue pt-4">
            <h5 className="text-center">
              <b>Total TTC : <br />
              <span className="c-blue">
              {temp_reservation.total_location.total_ttc + temp_reservation.total_options.total_ttc} TND
              </span>
              </b>
            </h5>
          </div>
          <div className="col-md-12">
            <hr />
          </div>
          <div className="col-md-12">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center" colSpan={4}>
                    <h4 className="text-center"><b>Options</b></h4>
                  </th>
                </tr>
                <tr>
                  <th className="text-center">Option</th>
                  <th className="text-center">Prix TTC</th>
                  <th className="text-center">Nombre</th>
                  <th className="text-center">Total TTC</th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(temp_reservation.listSelectedOptions).length==0 &&
                  (
                    <tr>
                      <td className="text-center" colSpan={4}>
                        Aucune option sélectionnée 
                      </td>
                    </tr>
                  )
                }
                {
                  temp_reservation.listSelectedOptions && 
                  Object.keys(temp_reservation.listSelectedOptions).map((key: string) => {

                    const selectedOption = temp_reservation.listSelectedOptions[Number(key)]; 
                    return (
                      <tr key={key}>
                        <td className="text-center">{selectedOption.libelle}</td>
                        <td className="text-center">
                          {selectedOption.prix_ttc} {selectedOption.type_prix=="PAR_JOUR" ? ' / Jour' : ''}
                        </td>
                        <td className="text-center">
                            {selectedOption.nbr_selected} / {selectedOption.nbr_max}
                        </td>
                        <td className="text-center">
                          {
                            !_.isUndefined(selectedOption?.nbr_selected) ? 
                            (selectedOption.prix_ttc * selectedOption?.nbr_selected) * (selectedOption.type_prix=="PAR_JOUR" ?  temp_reservation.numberOfDays : 1)
                            : 0
                          } 
                          TND
                        </td>
                      </tr>
                    )
                  })
                }
                 <tr className="aliceblue">
                 <td className="text-center" colSpan={3}><b>Total</b></td>
                  <td className="text-center"><b>{temp_reservation.total_options.total_ttc} TND</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        {/* location voiture */}
        <div className="row">
          <div className="col-md-12">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center" colSpan={3}>
                    <h4 className="text-center"><b>Location Voiture</b></h4>
                  </th>
                </tr>
                <tr>
                  <th className="text-center">Date</th>
                  <th className="text-center">heure</th>
                  <th className="text-center">Prix TTC</th>
                </tr>
              </thead>
              <tbody>
               
                {
                  temp_reservation.list_prix_par_jour && 
                  Object.keys(temp_reservation.list_prix_par_jour).map((key: string) => {
                    return (
                      <tr key={key}>
                        <td className="text-center">{dateFormatStr(key)}</td>
                        <td className="text-center">{timeFormatStr(key)  !=='00:00' ? timeFormatStr(key) : ''}</td>
                        <td className="text-center">{temp_reservation.list_prix_par_jour[key].prix_ttc} TND</td>
                      </tr>
                    )
                  })
                }
                 <tr className="aliceblue">
                 <td className="text-center" colSpan={2}><b>Total</b></td>
                  <td className="text-center"><b>{temp_reservation.total_location.total_ttc} TND</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ReservationDetails 
