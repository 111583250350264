import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "../../store";
import axios from 'axios';
import MarqueType from '../../../Types/MarqueType';


/**
 * Get list of marques.
 * @public
 */
export const findMarquesApi = createAsyncThunk('marques/findMarquesApi', async (data: null,{ rejectWithValue, dispatch }) => {

    try {
        const response = await axios.get('marques');
        return response.data;
    } catch (error: any) {
        console.log(error)
         return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});


const initialState: MarqueType[] = []

export const marqueSlice = createSlice({
    name: 'marques',
    initialState: initialState,
    reducers: {
        
    },
    extraReducers: builder => {
        builder.addCase(findMarquesApi.fulfilled, (state , action) => {
            return state = action.payload;
        });
        
    }
})

// export const {  } = marqueSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const ListMarques = (state: RootState) => state.marques

export default marqueSlice.reducer;