import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import { useAppDispatch } from '../../_redux/hooks';
import _ from 'lodash';
import MesReservations from '../../Components/Reservations/MesReservations/MesReservations';

const MesReservationsPage = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

 
  return (
  
      <>
        {/* <!-- =-=-=-=-=-=-= Breadcrumb =-=-=-=-=-=-= --> */}
        <Breadcrumb linkName="Acceuil"
          linkUrl="/"
          subLinkName="Mes Reservations"
          subLinkUrl={`/mesreservations`}
          title='' />

        {/* <!-- =-=-=-=-=-=-= Breadcrumb End =-=-=-=-=-=-= --> */}

        {/* <!-- =-=-=-=-=-=-= Main Content Area =-=-=-=-=-=-= --> */}
        <div className="main-content-area clearfix">

          {/* <!-- =-=-=-=-=-=-= Reservation form =-=-=-=-=-=-= --> */}
          <section className="section-padding no-top gray">
            {/* <!-- Main Container --> */}
            <div className="container">
              {/* <!-- Row --> */}
              <div className="row">
                {/* <!-- Middle Content Area --> */}
                <div className="col-md-12  col-sm-12 col-xs-12">
                  <MesReservations /> 
                </div>
                {/* <!-- Middle Content Area  End --> */}
              </div>
              {/* <!-- Row End --> */}
            </div>
            {/* <!-- Main Container End --> */}
          </section>
          {/* <!-- =-=-=-=-=-=-= Reservation Page End =-=-=-=-=-=-= --> */}

        </div>
      </>
  
  )
}

export default MesReservationsPage