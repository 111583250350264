import React from 'react'

const RentInfo = () => {
    return (
        <>
            {/* <!-- =-=-=-=-=-=-= Buy Or Sale =-=-=-=-=-=-= --> */}
            <section className="sell-box padding-top-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div className="sell-box-grid">
                                <div className="short-info">
                                    <h3> En bref, Allo rent c’est</h3>
                                    <h2><a href="#">Des options flexibles</a></h2>
                                    <p>Conducteurs additionnels offerts, forfait kilométrique modifiable à tout moment, sièges bébés.
                                   La voiture que vous conduisez est celle que vous avez réservée ! Chez Allo Rent, le modèle est garanti, fini les mauvaises surprises.</p>
                                </div>
                                <div className="text-center">
                                    <img className="img-responsive wow slideInLeft center-block" data-wow-delay="0ms" data-wow-duration="2000ms" src="assets/images/sell.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div className="sell-box-grid">
                                <div className="short-info">
                                    <h3> Un service client première classe</h3>
                                    <h2> <a href="#">Une voiture premium garantie</a></h2>
                                    <p>Toujours à portée de main (par chat, email ou téléphone), notre service client est disponible 24h/24 : avant, pendant et même après votre location.. </p>
                                </div>
                                <div className="text-center">
                                    <img className="img-responsive wow slideInRight center-block" data-wow-delay="0ms" data-wow-duration="2000ms" src="assets/images/sell-1.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- =-=-=-=-=-=-= Buy Or Sale End =-=-=-=-=-=-= --> */}
        </>
    )
}

export default RentInfo