import React from 'react'
import CarsListSection from '../../Components/Sections/CarsListSection'

const CarsPage = () => {
    return (
        <>
            {/* <!-- =-=-=-=-=-=-= Breadcrumb =-=-=-=-=-=-= --> */}
            <div className="page-header-area-2 gray">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="small-breadcrumb">
                                <div className=" breadcrumb-link">
                                    <ul>
                                        <li><a href="/">Acceuil</a></li>
                                        <li><a className="active" href="/cars">Voitures</a></li>
                                    </ul>
                                </div>
                                <div className="header-page">
                                    <h1>Total Voitures - (2)</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- =-=-=-=-=-=-= Breadcrumb End =-=-=-=-=-=-= --> */}
            <div className="main-content-area clearfix">
                <CarsListSection />
            </div>
        </>
    )
}

export default CarsPage