import React from 'react'
import Banner from '../../Components/Home/Banner'
import AdvancedSearch from '../../Components/Search/AdvancedSearch'
import CarsFeaturedGrid from '../../Components/Home/CarsFeaturedGrid'
import RentInfo from '../../Components/Home/RentInfo'
import CarsFilteredList from '../../Components/Home/CarsFilteredList'

const HomePage = () => {
  return (

    <>
      {/* <!-- =-=-=-=-=-=-= Home Banner  =-=-=-=-=-=-= --> */}
      <Banner />
      {/* <!-- =-=-=-=-=-=-= Home Banner End =-=-=-=-=-=-= --> */}
      <AdvancedSearch />
      {/* <!-- =-=-=-=-=-=-= Main Content Area =-=-=-=-=-=-= --> */}
      <div className="main-content-area clearfix">

        {/* // <!-- =-=-=-=-=-=-= CarsFilteredList Ads =-=-=-=-=-=-= --> */}
        <CarsFilteredList />
        {/* // <!-- =-=-=-=-=-=-= CarsFilteredList Ads End=-=-=-=-=-=-= --> */}

        {/* // <!-- =-=-=-=-=-=-= Featured Ads =-=-=-=-=-=-= --> */}
        <CarsFeaturedGrid />
        {/* // <!-- =-=-=-=-=-=-= Featured Ads End=-=-=-=-=-=-= --> */}

        {/* <!-- =-=-=-=-=-=-= Rent Cars info =-=-=-=-=-=-= --> */}
        <RentInfo />
        {/* <!-- =-=-=-=-=-=-= Rent Cars info =-=-=-=-=-=-= --> */}

      </div>
      {/* <!-- Main Content Area End -->  */}



    </>
  )
}

export default HomePage