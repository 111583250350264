import React from 'react'

const Footer = () => {
    return (
        <>
            {/* <!-- =-=-=-=-=-=-= FOOTER =-=-=-=-=-=-= --> */}
            <footer className="footer-bg">
                {/* <!-- Footer Content --> */}
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3  col-sm-6 col-xs-12">
                                {/* <!-- Info Widget --> */}
                                <div className="widget">
                                    <div className="logo"> <img alt="" src="/assets/images/logo-2.png" /> </div>
                                    <p><i className="fa fa-maps"></i> Livraison sur toute la tunisie.</p>
                                    <ul className="apps-donwloads">
                                        <li><img src="assets/images/googleplay.png" alt="" /></li>
                                        <li><img src="assets/images/appstore.png" alt="" /></li>
                                    </ul>
                                </div>
                                {/* <!-- Info Widget Exit --> */}
                            </div>
                            <div className="col-md-2 col-sm-6 col-xs-12">
                                {/* <!-- Follow Us --> */}
                                <div className="widget socail-icons">
                                    <h5>Suivez-nous</h5>
                                    <ul>
                                        <li><a className="Facebook" ><i className="fa fa-facebook"></i></a><span>Facebook</span></li>
                                        <li><a className="Twitter" href=""><i className="fa fa-twitter"></i></a><span>Twitter</span></li>
                                        <li><a className="Linkedin" href=""><i className="fa fa-linkedin"></i></a><span>Linkedin</span></li>
                                        <li><a className="Google" href=""><i className="fa fa-google-plus"></i></a><span>Google+</span></li>
                                    </ul>
                                </div>
                                {/* <!-- Follow Us End --> */}
                            </div>
                            <div className="col-md-2  col-sm-6 col-xs-12">
                                {/* <!-- Follow Us --> */}
                                <div className="widget my-quicklinks">
                                    <h5>Liens rapides</h5>
                                    <ul >
                                        <li><a href="#">A propos</a></li>
                                        <li><a href="#">Faqs</a></li>
                                        <li><a href="#">Contact</a></li>
                                    </ul>
                                </div>
                                {/* <!-- Follow Us End --> */}
                            </div>
                            <div className="col-md-5  col-sm-6 col-xs-12">
                                {/* <!-- Newslatter --> */}
                                <div className="widget widget-newsletter">
                                    <h5>Inscrivez-vous à la newsletter hebdomadaire</h5>
                                    <div className="fieldset">
                                        <p>Nous pouvons vous envoyer des informations sur des événements, des webinaires, des produits et des services connexes que nous croyons.</p>
                                        <form>
                                            <input className="" placeholder="Enter your email address" type="text" />
                                            <input className="submit-btn" name="submit" value="Submit" type="submit" />
                                        </form>
                                    </div>
                                </div>
                                <div className="copyright">
                                    <p>© 2023 Carspot All rights reserved. </p>
                                </div>
                                {/* <!-- Newslatter --> */}
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
            {/* <!-- =-=-=-=-=-=-= FOOTER END =-=-=-=-=-=-= --> */}
        </>
    )
}

export default Footer