import React from 'react'

const CardCars = () => {
    return (
        <>
            <div className="ads-list-archive">
                {/* <!-- Image Block --> */}
                <div className="col-lg-5 col-md-5 col-sm-5 no-padding">
                    {/* <!-- Img Block --> */}
                    <div className="ad-archive-img">
                        <a href="#">
                            <img className="img-responsive" src="assets/images/posting/2.jpg" alt="" />
                        </a>
                    </div>
                    {/* <!-- Img Block --> */}
                </div>
                {/* <!-- Ads Listing --> */}
                <div className="clearfix visible-xs-block"></div>
                {/* <!-- Content Block --> */}
                <div className="col-lg-7 col-md-7 col-sm-7 no-padding">
                    {/* <!-- Ad Desc --> */}
                    <div className="ad-archive-desc">
                        {/* <!-- Price --> */}
                        <img alt="" className="pull-right" src="assets/images/certified.png" />
                        {/* <!-- Title --> */}
                        <h3>Porsche 911 Carrera 2017 </h3>
                        {/* <!-- Category --> */}
                        <div className="category-title"> <span><a href="#">Car &amp; Bikes</a></span> </div>
                        {/* <!-- Short Description --> */}
                        <div className="clearfix visible-xs-block"></div>
                        <p className="hidden-sm">Lorem ipsum dolor sit amet, quem convenire interesset ut vix, maiestatis inciderint no, eos in elit dicat.....</p>
                        {/* <!-- Ad Features --> */}
                        <ul className="short-meta list-inline">
                            <li><a href="#">Petrol</a></li>
                            <li><a href="#">32,190 KMs</a></li>
                            <li><a href="#">Manual </a></li>
                            <li><a href="#">Uk London</a></li>
                        </ul>
                        {/* <!-- Ad History --> */}
                        <div className="ad-price-simple">$77,000</div>
                        <div className="clearfix archive-history">
                            <div className="last-updated">Last Updated: 1 day ago</div>
                            <div className="ad-meta"> <a className="btn save-ad"><i className="fa fa-heart-o"></i> Save Ad.</a> <a className="btn btn-success"><i className="fa fa-phone"></i> View Details.</a> </div>
                        </div>
                    </div>
                    {/* <!-- Ad Desc End --> */}
                </div>
                {/* <!-- Content Block End --> */}
            </div>
        </>
    )
}

export default CardCars