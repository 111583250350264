import React, { useEffect, useState } from 'react'
import CardFeatured from './CardFeatured';
import { useAppDispatch, useAppSelector } from '../../_redux/hooks';
import { ListAllModeles, findAllModelesApi } from '../../_redux/Slices/modeles/modeleSlice';
import _ from "lodash"
const CarsFeaturedGrid = () => {

    const dispatch = useAppDispatch();
    const modeles = useAppSelector(ListAllModeles);
    const [result_modeles, setResultModeles] = useState([] as any)
    const findAllModeles = async () => {
        dispatch(findAllModelesApi(null))
    }
    useEffect(() => {
        findAllModeles();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const temp_model_ordered = _.orderBy(modeles, ['updated_at'], ['desc']);
        const last_result_model = _.take(temp_model_ordered,9);
        setResultModeles(last_result_model)
        // console.log('temp_model_ordered',temp_model_ordered)
        // console.log('last_result_model',last_result_model)
        // console.log('result_modeles',result_modeles)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modeles])

    return (
        <>
            {/* // <!-- =-=-=-=-=-=-= Featured Ads =-=-=-=-=-=-= --> */}
            <section className="custom-padding gray">
                {/* <!-- Main Container --> */}
                <div className="container">
                    {/* <!-- Row --> */}
                    <div className="row">
                        {/* <!-- Heading Area --> */}
                        <div className="heading-panel">
                            <div className="col-xs-12 col-md-12 col-sm-12 left-side">
                                {/* <!-- Main Title --> */}
                                <h1>Les <span className="heading-color">derniers </span> modèles</h1>
                            </div>
                        </div>
                        {/* <!-- Middle Content Box --> */}
                        <div className="row grid-style-2 ">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                {/* <!-- Ads Archive --> */}
                                {
                                    result_modeles.map((model:any) => (
                                        <CardFeatured item={model} key={model.id} />
                                    ))
                                }
                            </div>
                        </div>
                        {/* <!-- Middle Content Box End --> */}
                    </div>
                    {/* <!-- Row End --> */}
                </div>
                {/* <!-- Main Container End --> */}
            </section>
            {/* <!-- =-=-=-=-=-=-= Featured Ads End =-=-=-=-=-=-= --> */}
        </>
    )

}

export default CarsFeaturedGrid