import React from 'react'

const ConditionPanel = () => {
    return (
        <>
            <div className="panel panel-default">
                {/* <!-- Heading --> */}
                <div className="panel-heading" role="tab" id="headingThree">
                    <h4 className="panel-title">
                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Condition
                        </a>
                    </h4>
                </div>
                {/* <!-- Content --> */}
                <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body">
                        <div className="skin-minimal">
                            <ul className="list">
                                <li>
                                    <input tabIndex={7} type="radio" id="minimal-radio-1" name="minimal-radio" />
                                    <label htmlFor="minimal-radio-1">New</label>
                                </li>
                                <li>
                                    <input tabIndex={8} type="radio" id="minimal-radio-2" name="minimal-radio" />
                                    <label htmlFor="minimal-radio-2">Used</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConditionPanel