import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from "../../store";
import { RegisterDataType } from '../../../Types/RegisterDataType';
import axios from 'axios';
import { ActivateAccountType } from '../../../Types/ActivateAccountType';
import { LoginDataType } from '../../../Types/LoginDataType';
import { ResetPasswordFormType } from '../../../Types/FormType/ResetPasswordFormType';


/**
 * get current userConnected.
 *
 * @public
 */
export const CurrentUserApi = createAsyncThunk('auth/CurrentUser', async (data: null, { rejectWithValue, dispatch }) => {

    try {
        const response = await axios.get('users/user');
        return response.data;
    } catch (error: any) {
        console.log(error)
         return rejectWithValue({
            success: false,
            message: error.response.data.message,
            action: error.response.data.action,
            data: error.response.data.data,
        });
    }
});

/**
 * logout function.
 *
 * @private
 */
export const logoutApi = createAsyncThunk('auth/logout', async (data: null,{ rejectWithValue, dispatch }) => {

    try {
        localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME as string);

        return {success: true};
    } catch (error: any) {
        console.log(error)
         return rejectWithValue({
            success: false,
            message: error.response.data.message,
            action: error.response.data.action,
            data: error.response.data.data,
        });
    }
});

/**
 * checkClient function.
 *
 * @param {string} email_id
 * @param {string} token_verification
 * @public
 */
export const checkClientByTokensApi = createAsyncThunk('auth/checkClientByTokensApi', async (data: ActivateAccountType, { rejectWithValue, dispatch }) => {

    try {
        const response = await axios.get('clients/checkClientByTokensApi',  {params : data});
 
        return response.data;
    } catch (error: any) {
        console.log(error)
         return rejectWithValue({
            success: false,
            message: error.response.data.message,
            action: error.response.data.action,
            data: error.response.data.data,
        });
    }
});

/**
 * login function.
 *
 * @param {string} email
 * @param {string} password
 * @public
 */
export const loginApi = createAsyncThunk('auth/login', async (data: LoginDataType, { rejectWithValue, dispatch }) => {

    try {
        const response = await axios.post('auth/login', data);
        //localStorage.setItem(process.env.REACT_APP_TOKEN_NAME as string, response.data.token);

        return response.data;
    } catch (error: any) {
        console.log(error)
         return rejectWithValue({
            success: false,
            message: error.response.data.message,
            action: error.response.data.action,
            data: error.response.data.data,
        });
    }
});

/**
 * register function.
 *
 * @param {string} nom
 * @param {string} prenom
 * @param {string} email
 * @param {string} mobile
 * @param {string} password
 * @param {string} confirm_password
 * @public
 */
export const registerApi = createAsyncThunk('auth/register', async (data: RegisterDataType, { rejectWithValue, dispatch }) => {

    try {
        const response = await axios.post('auth/register', data);

        return response.data;
    } catch (error: any) {
        console.log(error)
         return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});

/**
 * register function.
 *
 * @param {string} password
 * @param {string} confirm_password
 * @param {string} email_id
 * @param {string} token_verification
 * @public
 */
export const resetPasswordApi = createAsyncThunk('auth/resetPasswordApi', async (data: ResetPasswordFormType, { rejectWithValue, dispatch }) => {

    try {
        const response = await axios.post('auth/resetPasswordApi', data);

        return response.data;
    } catch (error: any) {
        console.log(error)
         return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});

/**
 * Activation de compte par email.
 *
 * @param {string} email_id
 * @param {string} token_verification
 * @public
 */
export const activateAccountApi = createAsyncThunk('auth/activate-account', async (data: ActivateAccountType, { rejectWithValue, dispatch }) => {

    try {
        const response = await axios.post('auth/activate-account', data);
        return response.data;
    } catch (error: any) {
        console.log('error', error)
        return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});

/**
 * Re-envoyer un email de validation du compte.
 *
 * @param {string} email_id
 * @param {string} token_verification
 * @public
 */
export const resendEmailVerificationApi = createAsyncThunk('auth/resend-token-verification', async (data: ActivateAccountType, { rejectWithValue, dispatch }) => {

    try {
        const response = await axios.post('auth/resend-token-verification', data);
        return response.data;
    } catch (error: any) {
        console.log('error', error)
        return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});

interface initialStateI {
    loggedIn: boolean,
    token: string | null
    user: object
}

const initialState: initialStateI = {
    loggedIn: false,
    token: null,
    user: {}
}



export const AuthSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        login: (state: initialStateI, action: PayloadAction<{ token: string, loggedIn: boolean }>) => {
            return state = { ...state, loggedIn: true, token: action.payload?.token }
        },
        logout: (state: initialStateI, action: PayloadAction) => {
            return state = { ...state, loggedIn: false, token: null }
        },
        checkUser: (state: initialStateI, action: PayloadAction<{ token: string, loggedIn: boolean }>) => {
            return state = { ...state, loggedIn: action.payload.loggedIn }
        }
    },
    extraReducers: builder => {
        builder.addCase(registerApi.fulfilled, (state, action) => {
            state.loggedIn = false;
            state.token = '';
            state.user = {};
        });
        builder.addCase(loginApi.fulfilled, (state, action) => {
            state.loggedIn = true;
            state.token = action.payload.token;
        });
        builder.addCase(logoutApi.fulfilled, (state, action) => {
            state.loggedIn = false;
            state.token = '';
            state.user = {};
        });
        builder.addCase(CurrentUserApi.fulfilled, (state, action) => {
            state.user = action.payload;
        });
    }
})

export const { login, logout, checkUser } = AuthSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getToken = (state: RootState) => state.auth.token
export const isLoggedIn = (state: RootState) => state.auth.loggedIn
export const UserConnected = (state: RootState) => state.auth.user


export default AuthSlice.reducer;