import React, { useEffect } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import IndexLayout from './Layouts/IndexLayout';
import HomePage from './Pages/Home/HomePage';
import LoginPage from './Pages/Auth/LoginPage';
import RegisterPage from './Pages/Auth/RegisterPage';
import { useLayoutEffect } from 'react';
import CarsPage from './Pages/Cars/CarsPage';
import './index.css'
import './App.css'
import './responsive-styles.css'
import ActivateAccountPage from './Pages/Auth/ActivateAccountPage';
import ProfilePage from './Pages/Users/ProfilePage';
import { useAppDispatch, useAppSelector } from './_redux/hooks';
import { CurrentUserApi, getToken, isLoggedIn } from './_redux/Slices/auth/authSlice';
import customJwtPayload from './Types/customJwtPayload';
import jwt_decode from 'jwt-decode'
import UserType from './Types/UserType';
import ReservationPage from './Pages/Reservations/ReservationPage';
import axios from 'axios';
import ResetPasswordAndActivateAccountPage from './Pages/Auth/ResetPasswordAndActivateAccountPage';
import MesReservationsPage from './Pages/Reservations/MesReservationsPage';
 import ReservationPaiementPage from './Pages/Reservations/ReservationPaiementPage';




function App() {

  const loggedIn = useAppSelector(isLoggedIn);
  const token: string = useAppSelector(getToken) as string
  const dispatch = useAppDispatch();


  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  //axios.defaults.headers.Authorization = `${localStorage.getItem(process.env.REACT_APP_TOKEN_NAME as string)}`
  axios.defaults.headers.Authorization = token
  
  useEffect(() => {
      dispatch(CurrentUserApi(null))
  }, [loggedIn])
  
  function PrivateOutlet() {
    return loggedIn ? <Outlet /> : <Navigate to="/login" />;
  }

  function UserConnected() {
    return !loggedIn ? <Outlet /> : <Navigate to="/" />;
  }


  function IsAdmin() {
    if (loggedIn) {
      const decodedToken = jwt_decode<customJwtPayload>(token)
      const user: UserType = decodedToken.user;
      const role_id = user.role_id
      return role_id === 'Admin' ? <Outlet /> : <Navigate to="/" />;
    } else {
      return <Navigate to="/" />;
    }

  }
  

  useLayoutEffect(() => {

    var script = document.createElement("script");
    script.src = "/assets/js/jquery.min.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/bootstrap.min.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/easing.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/carspot-menu.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/carspot-menu.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/jquery.appear.min.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/jquery.countTo.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/select2.min.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/nouislider.all.min.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/carousel.min.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/slide.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/imagesloaded.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/isotope.min.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/icheck.min.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/jquery-migrate.min.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/color-switcher.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/jquery.fancybox.min.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/wow.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/js/custom.js";
    script.async = true;
    document.body.appendChild(script);

  }, [loggedIn]);
  return (
    <Routes>

      {/**
         *   Public access
         **/}
      <Route path="/" element={<IndexLayout />}>
        <Route index element={<HomePage />} />
        <Route path="/cars" element={<CarsPage />} />
        <Route path="/cars/:id" element={<ReservationPage />} />
        <Route path="/activate-account/:email_id/:token_verification" element={<ActivateAccountPage />} />
        <Route path="/reset-password-and-activate-account/:email_id/:token_verification" element={<ResetPasswordAndActivateAccountPage />} />

        {/**
         * only for anonymous
         **/}
        <Route element={<UserConnected />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
        </Route>


        {/**
         * Only for User connected
         */}
        <Route element={<PrivateOutlet />}>
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/mesreservations" element={<MesReservationsPage />} />
        <Route path="/mesreservations/:id" element={<ReservationPaiementPage />} />
        </Route>

       
      </Route>



    </Routes>
  );
}

export default App;
