import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import ReservationCarSection from '../../Components/Reservations/ReservationCarSection';
import { useAppDispatch } from '../../_redux/hooks';
import { findModeleByIdApi } from '../../_redux/Slices/modeles/modeleSlice';
import _ from 'lodash';

const ReservationPage = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const FindModeleById = async () => {
    await dispatch(findModeleByIdApi(params.id as string))
  }

  useEffect(() => {
    if(params.id){
      FindModeleById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])


  return (
  
      <>
        {/* <!-- =-=-=-=-=-=-= Breadcrumb =-=-=-=-=-=-= --> */}
        <Breadcrumb linkName="Acceuil"
          linkUrl="/"
          subLinkName="Reservation"
          subLinkUrl={`/cars/${params.id}`}
          title='' />

        {/* <!-- =-=-=-=-=-=-= Breadcrumb End =-=-=-=-=-=-= --> */}

        {/* <!-- =-=-=-=-=-=-= Main Content Area =-=-=-=-=-=-= --> */}
        <div className="main-content-area clearfix">

          {/* <!-- =-=-=-=-=-=-= Reservation form =-=-=-=-=-=-= --> */}
          <section className="section-padding no-top gray">
            {/* <!-- Main Container --> */}
            <div className="container">
              {/* <!-- Row --> */}
              <div className="row">
                {/* <!-- Middle Content Area --> */}
                <div className="col-md-12  col-sm-12 col-xs-12">
                  <ReservationCarSection /> 
                </div>
                {/* <!-- Middle Content Area  End --> */}
              </div>
              {/* <!-- Row End --> */}
            </div>
            {/* <!-- Main Container End --> */}
          </section>
          {/* <!-- =-=-=-=-=-=-= Reservation Page End =-=-=-=-=-=-= --> */}

        </div>
      </>
  
  )
}

export default ReservationPage