import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "../../store";
import axios from 'axios';
import LieuxType from '../../../Types/LieuxType';


/**
 * Get list of marques.
 * @public
 */
export const findLieuxApi = createAsyncThunk('marques/findLieuxApi', async (data: null,{ rejectWithValue, dispatch }) => {

    try {
        const response = await axios.get('lieux');
        return response.data;
    } catch (error: any) {
        console.log(error)
         return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});


const initialState: LieuxType[] = []

export const lieuxSlice = createSlice({
    name: 'lieux',
    initialState: initialState,
    reducers: {
        
    },
    extraReducers: builder => {
        builder.addCase(findLieuxApi.fulfilled, (state , action) => {
            return state = action.payload;
        });
        
    }
})

// export const {  } = marqueSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const ListLieux = (state: RootState) => state.lieux

export default lieuxSlice.reducer;