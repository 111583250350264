import moment from "moment";

/**
 * 
 * @param date Date
 * @param heure Date
 * @returns Moment date time
 */
export const dateFormat = (date: Date, heure: Date | null = null) => {
    const day = moment(date).format('YYYY-MM-DD');
    const time = heure ? moment(heure).format('HH-mm') : '';
    return moment(day + ' ' + time);
}

/**
 * 
 * @param date Datetime
 * @returns Moment date time
 */
export const dateFormatStr = (datetime:string) => {
    return moment(datetime).format('DD/MM/YYYY');
}

/**
 * 
 * @param date Datetime
 * @returns Moment date time
 */
export const timeFormatStr = (datetime:string) => {
    return moment(datetime).format('HH:mm');
}



export const getAllDatetimesBetween = (firstDatetime: moment.Moment, lastDatetime: moment.Moment): string[] => {

    // Array to store result datetimes
    const resultDatetimes: string[] = [];

    // Iterate and add each interval to the start date
    for (let currentDatetime = firstDatetime; currentDatetime <= lastDatetime; currentDatetime.add(24, 'hour')) {
        if (currentDatetime.format('YYYY-MM-DD') == lastDatetime.format('YYYY-MM-DD')) {
            resultDatetimes.push(lastDatetime.format('YYYY-MM-DD HH:mm:ss'));
        }else{
            resultDatetimes.push(currentDatetime.format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    return resultDatetimes;
}


