import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../_redux/hooks';
import { activateAccountApi, resendEmailVerificationApi } from '../../_redux/Slices/auth/authSlice';
import { ActivateAccountType } from '../../Types/ActivateAccountType';
import { useParams } from "react-router-dom"
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';

const ActivateAccountPage = () => {
  const params = useParams();

  const [Message, setMessage] = useState('');
  const [AlertType, setAlertType] = useState('alert-success');
  const [redirectTo, setRedirectTo] = useState('login');
  const dispatch = useAppDispatch();

  const data: ActivateAccountType = {
    email_id: params.email_id,
    token_verification: params.token_verification
  }
  useEffect(() => {

    activateAccount(data)

    return () => {
      setMessage('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const activateAccount = async (data: ActivateAccountType) => {
    const response = await dispatch(activateAccountApi(data))
    if (response.payload.success) {
      setAlertType('alert-success')
      setRedirectTo('login')
    } else {
      setAlertType('alert-danger')
      setRedirectTo('resend-email')
    }
    setMessage(response.payload.message);

  }

  const resendEmailVerification = async () => {
    const response = await dispatch(resendEmailVerificationApi(data))
    if (response.payload.success) {
      setAlertType('alert-success')
    } else {
      setAlertType('alert-danger')
    }
    setMessage(response.payload.message);
  }


  return (
    <>
      {/* <!-- =-=-=-=-=-=-= Breadcrumb =-=-=-=-=-=-= --> */}
      <Breadcrumb linkName="Acceuil"
        linkUrl="/"
        subLinkName="activation du compte"
        subLinkUrl='#'
        title='activation du compte' />

      {/* <!-- =-=-=-=-=-=-= Breadcrumb End =-=-=-=-=-=-= --> */}

      {/* <!-- =-=-=-=-=-=-= Main Content Area =-=-=-=-=-=-= --> */}
      <div className="main-content-area clearfix">

        {/* <!-- =-=-=-=-=-=-= Login form =-=-=-=-=-=-= --> */}
        <section className="section-padding no-top gray">
          {/* <!-- Main Container --> */}
          <div className="container">
            {/* <!-- Row --> */}
            <div className="row">
              {/* <!-- Middle Content Area --> */}
              <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 text-center">

                {
                  Message && (
                    <>
                      <div className={"alert " + AlertType} role="alert">
                        {Message}
                      </div>
                      {
                        redirectTo === 'login' ?
                          (
                            <a href="/login" className="btn btn-info text-center">
                              <i className="fa fa-user"></i>  Login
                            </a>
                          ) :
                          (
                            <button className="btn btn-info text-center" onClick={e => resendEmailVerification()}>
                              <i className="fa fa-send"></i>  Ré-envoyer un email de validation
                            </button>
                          )
                      }



                    </>
                  )
                }

              </div>
              {/* <!-- Middle Content Area  End --> */}
            </div>
            {/* <!-- Row End --> */}
          </div>
          {/* <!-- Main Container End --> */}
        </section>
        {/* <!-- =-=-=-=-=-=-= Login Page End =-=-=-=-=-=-= --> */}

      </div>
    </>
  )
}

export default ActivateAccountPage