import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../_redux/hooks'
import { getToken, isLoggedIn } from '../../_redux/Slices/auth/authSlice'
import jwtDecode from 'jwt-decode'
import customJwtPayload from '../../Types/customJwtPayload'
import { UserConnected } from '../../_redux/Slices/auth/authSlice'

const NavigationMenu = () => {

    const [user, setUser] = useState({} as any)
    const token: string = useAppSelector(getToken) as string
    const loggedIn: boolean = useAppSelector(isLoggedIn)
    const currentUser: object = useAppSelector(UserConnected)

    /**
     * decode User token
     */
    useEffect(() => {
        if (currentUser) {
            setUser(currentUser)
            // console.log('user =====>', user, user.role_id)
        }

    }, [currentUser])

    return (
        <nav id="menu-1" className="mega-menu">
            {/* <!-- menu list items container --> */}
            <section className="menu-list-items">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            {/* <!-- menu logo --> */}
                            <ul className="menu-logo">
                                <li>
                                    <a href="/"><img src="/assets/images/logo-2.png" alt="logo" /> </a>
                                </li>
                            </ul>
                            {/* <!-- menu links --> */}
                            <ul className="menu-links">
                                {/* <!-- active class --> */}

                                <li >
                                    <a href="/">Acceuil</a>
                                </li>
                                <li >
                                    <a href="/">Voitures</a>
                                </li>
                                {/* <li >
                                    <a href="/cars">Voitures</a>
                                </li> */}
                                <li >
                                    <a href="/">A props</a>
                                </li>
                                <li >
                                    <a href="/">Contact</a>
                                </li>
                                {
                                    loggedIn && (
                                        <li>
                                            <a href="/mesreservations">Mes reservations</a>
                                        </li>
                                    )
                                }
                                

                                {
                                    loggedIn && (
                                        <li >
                                            <a href="#" onClick={e => e.preventDefault()}> <i className="fa fa-user"> </i> Profile <i className="fa fa-angle-down fa-indicator"></i></a>
                                            {/* <!-- drop down multilevel  --> */}
                                            <ul className="drop-down-multilevel" >
                                                <li><a href="/">Mon profile</a></li>
                                                <li><a href="/mesreservations">Mes reservations</a></li>
                                                <li><a href="/"> Mes Favorites</a></li>
                                            </ul>
                                        </li>
                                    )
                                }

                            </ul>
                            <ul className="menu-search-bar">
                                <li>
                                    <a>
                                        <div className="contact-in-header clearfix">
                                            <i className="flaticon-customer-service"></i>
                                            <span>
                                                Contact
                                                <br />
                                                <strong>+216 98 377 774</strong>
                                            </span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </nav>
    )
}

export default NavigationMenu