import React from 'react'
import { useAppSelector } from '../../_redux/hooks'
import { ListModeles } from '../../_redux/Slices/modeles/modeleSlice'
import CardFiltered from './CardFiltered'
import _ from 'lodash'


const CarsFilteredList = () => {
  const modeles: any = useAppSelector(ListModeles)
  return (
    <>
      {
        !_.isEmpty(modeles) ? (
          <>
            {/* // <!-- =-=-=-=-=-=-= Featured Ads =-=-=-=-=-=-= --> */}
            <section className="custom-padding gray">
              {/* <!-- Main Container --> */}
              <div className="container">
                {/* <!-- Row --> */}
                <div className="row">
                  {/* <!-- Heading Area --> */}
                  <div className="heading-panel">
                    <div className="col-xs-12 col-md-12 col-sm-12 left-side">
                      {/* <!-- Main Title --> */}
                      <h3>{modeles.length}  Modeles disponible </h3>
                    </div>
                  </div>
                  {/* <!-- Middle Content Box --> */}
                  <div className="row grid-style-2 ">
                    <div className="col-md-12 col-xs-12 col-sm-12">
                      <ul className="list-unstyled">
                        <li>
                          {
                            modeles.map((modele : any)=> (
                              <CardFiltered  key={modele.id} modele={modele}/>
                            ))
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- Middle Content Box End --> */}
                </div>
                {/* <!-- Row End --> */}
              </div>
              {/* <!-- Main Container End --> */}
            </section >
            {/* <!-- =-=-=-=-=-=-= Featured Ads End =-=-=-=-=-=-= --> */}
          </>
        ) :
        null
      }


    </>
  )
}

export default CarsFilteredList