import React, { useEffect, useState } from 'react'
import { ToastSuccess } from '../Toasts/ToastSuccess'
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../_redux/hooks';
import { useForm } from 'react-hook-form';
import { LoginDataType } from '../../Types/LoginDataType';
import { checkClientByTokensApi, loginApi, resendEmailVerificationApi, resetPasswordApi } from '../../_redux/Slices/auth/authSlice';
import { ToastWarning } from '../Toasts/ToastWarning';
import { ResetPasswordSchema } from '../../Schemas/ResetPasswordSchema';
import { ResetPasswordFormType } from '../../Types/FormType/ResetPasswordFormType';
import { ActivateAccountType } from '../../Types/ActivateAccountType';



const ResetPasswordForm = () => {

    const navigate = useNavigate();
    const formOptions = { resolver: yupResolver(ResetPasswordSchema) };
    const { register, handleSubmit, formState, reset } = useForm<ResetPasswordFormType>(formOptions);
    const { errors } = formState;
    const params = useParams();   
    
    const dispatch = useAppDispatch();

    const [HideForm, setHideForm] = useState(false)
    const [Message, setMessage] = useState('');
    const [AlertType, setAlertType] = useState('alert-success');

    const checkClientByTokens = async () => {
       const response = await dispatch(checkClientByTokensApi(
            {
                email_id: params.email_id,
                token_verification: params.token_verification
            }
        ))
         if (response.payload.success) {
            ToastSuccess(response.payload.message)
            setMessage('')
        } else {
            setHideForm(true);
            setMessage(response.payload.message)
            setAlertType('alert-danger')

            /**
             * cas d'un email pas encore valider
             */
            // ToastWarning(response.payload.message);
             
        }


    }
    useEffect(() => {
        checkClientByTokens()
        reset({
            password: '',
            confirm_password: '',
            email_id: params.email_id,
            token_verification: params.token_verification
        });
    }, [params]);
    /**
     * 
     * @param data ResetPasswordFormType<password: string, confirm_password: string>
     */
    const onSubmit = async (data: ResetPasswordFormType) => {

         const response = await dispatch(resetPasswordApi(data))
        setMessage(response.payload.message)
        if (response.payload.success) {
            ToastSuccess(response.payload.message)
            navigate('/login')
        } else {
            setAlertType('alert-danger')
            /**
             * cas d'un email pas encore valider
             */
            ToastWarning(response.payload.message);
        }
    }

    const resendEmailVerification = async () => {
        const response = await dispatch(resendEmailVerificationApi({
            email_id: params.email_id,
            token_verification: params.token_verification,
            reset_password: true
        }))
        if (response.payload.success) {
          setAlertType('alert-success')
        } else {
          setAlertType('alert-danger')
        }
        setMessage(response.payload.message);
      }
    
 

    return (
        <>
            {
                Message && (
                    <>
                        <div className={`alert ${AlertType}`} role="alert">{Message}</div>
                        <button className="btn btn-info text-center" onClick={e => resendEmailVerification()}>
                            <i className="fa fa-send"></i>  Ré-envoyer un email de validation
                        </button>
                    </>
                )
            }
            {
                !HideForm && (
                    <form onSubmit={handleSubmit(onSubmit)}> 
                        <h2 className="no-span"><b>(Sécurite)</b></h2>
                        <input type="hidden" {...register('email_id')} />
                        <input type="hidden" {...register('token_verification')} />
                        <div className="form-group">
                            <label>Mot de Passe</label>
                            <input placeholder="Mot de Passe" type="password"
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                {...register('password')} />
                            <div className="invalid-feedback">{errors.password?.message}</div>

                        </div>
                        <div className="form-group">
                            <label>Confirmer Mot de passe</label>
                            <input placeholder="Confirmer Mot de passe" type="password"
                                className={`form-control ${errors.confirm_password ? 'is-invalid' : ''}`}
                                {...register('confirm_password')} />
                            <div className="invalid-feedback">{errors.confirm_password?.message}</div>
                        </div>
                        <button className="btn btn-theme btn-lg btn-block">Enregistrer</button>
                    </form>
                )
            }
            
        </>
    )
}

export default ResetPasswordForm