import React from 'react'

const YearPanel = () => {
    return (
        <>
            <div className="panel panel-default">
                {/* <!-- Heading --> */}
                <div className="panel-heading" role="tab" id="heading8">
                    <h4 className="panel-title">
                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse8" aria-expanded="false" aria-controls="collapse8">
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Select Year
                        </a>
                    </h4>
                </div>
                {/* <!-- Content --> */}
                <div id="collapse8" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading8">
                    <div className="panel-body">
                        {/* <!-- Year List -->                               */}
                        <div className="skin-minimal">
                            <ul className="list">
                                <li>
                                    <input type="checkbox" id="y1" />
                                    <label htmlFor="y1">2017 </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="y2" />
                                    <label htmlFor="y2">2016 </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="y3" />
                                    <label htmlFor="y3">2015 </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="y4" />
                                    <label htmlFor="y4">2014 </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="y5" />
                                    <label htmlFor="y5">2013</label>
                                </li>
                                <li>
                                    <input type="checkbox" id="y6" />
                                    <label htmlFor="y6">2012 </label>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- Year List End -->  */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default YearPanel