import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from "../../store";
import axios from 'axios';
import AdvancedSearchType from '../../../Types/AdvancedSearchType';
import _ from 'lodash';
import moment from 'moment';


/**
 * Get list of modeles By Search.
 * @public
 */
export const findModelesApi = createAsyncThunk('modeles/findModelesApi', async (data: AdvancedSearchType,{ rejectWithValue, dispatch }) => {

    try {
        const params =  '?' + new URLSearchParams(data).toString();
        const response = await axios.get(`modeles${params}`);
        return response.data;
    } catch (error: any) {
        console.log(error)
         return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});


/**
 * Get list of modeles .
 * @public
 */
export const findAllModelesApi = createAsyncThunk('modeles/findAllModelesApi', async (data: null,{ rejectWithValue, dispatch }) => {

    try {
        const response = await axios.get(`modeles`);
        return response.data;
    } catch (error: any) {
        console.log(error)
         return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});

/**
 * Get modele By Id.
 * @public
 */
export const findModeleByIdApi = createAsyncThunk('modeles/findModeleByIdApi', async (data: string,{ rejectWithValue, dispatch }) => {

    try {
        const response = await axios.get(`modeles/${data}`);
        return response.data;
    } catch (error: any) {
        console.log(error)
         return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});


const initialState :any = {
    modeles_filtered : [],
    all_modeles: [],
    selected_modele: {}
}

export const modeleSlice = createSlice({
    name: 'modeles',
    initialState: initialState,
    reducers: {
        changePrixJour: (state, action) => {
            state.selected_modele.prix_jour = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(findModelesApi.fulfilled, (state , action) => {
            state.modeles_filtered = action.payload;
        });
        builder.addCase(findAllModelesApi.fulfilled, (state , action) => {
            state.all_modeles = action.payload;
        });
        builder.addCase(findModeleByIdApi.fulfilled, (state , action) => {

            let selected_model = action.payload;

            //get prix par jour element
            let current_date = moment();
            var prix_jour = _.filter(selected_model.categorie.Parametrage_prix, prix => moment(prix.date_debut)<= current_date && moment(prix.date_fin) >= current_date )
            prix_jour = prix_jour.length > 0 ? _.orderBy(prix_jour, ['nbr_jour'], ['asc']) : [];

            const list_prix = selected_model.categorie.Parametrage_prix;

            selected_model.prix_jour = prix_jour;
            selected_model.list_prix = list_prix;

            // console.log('prix_jour', prix_jour)
            // console.log('list_prix', list_prix)
            state.selected_modele =selected_model;
        });
        
    }
})

export const { changePrixJour } = modeleSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const ListModeles = (state: RootState) => state.modeles.modeles_filtered;
export const ListAllModeles = (state: RootState) => state.modeles.all_modeles;
export const selectedModele = (state: RootState) => state.modeles.selected_modele;

export default modeleSlice.reducer;