import { object, string , bool, ref} from 'yup';
// import { array, number, ref, object, string, mixed, BooleanSchema, boolean, bool } from 'yup';


export const RegisterSchema = object({

    nom: string().required('Nom Obligatoire!!'),
    prenom: string().required('Prenom Obligatoire!!'),
    mobile: object().shape({
        status : bool().required('Mobile Obligatoire!!').oneOf([true], 'Veuillez bien saisir un Numéro valide')
    }),
    email: string().email("Veuillez bien saisir un email valide").required('Email Obligatoire!!'),
    password: string().min(6, 'Il faut entrer au moins 6 caractères.').required('Mot de passe Obligatoire!!'),
    confirm_password: string()
       .oneOf([ref('password')], 'Les mots de passe doivent être identiques!!')
  });

