import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../_redux/hooks'
import { ListMarques, findMarquesApi } from '../../_redux/Slices/marques/marqueSlice';
import { ListCarrosseries, findCarrosseriesApi } from '../../_redux/Slices/carrosseries/carrosserieSlice';
import { findModelesApi } from '../../_redux/Slices/modeles/modeleSlice';
import { ToastWarning } from '../Toasts/ToastWarning';
import { ToastSuccess } from '../Toasts/ToastSuccess';

const AdvancedSearch = () => {

   const dispatch = useAppDispatch();
   const marques = useAppSelector(ListMarques);
   const carrosseries = useAppSelector(ListCarrosseries);

   const [search, setSearch] = useState({
      search: '',
      marque_id: '',
      annee: '',
      carrosserie: ''
   })



   const findMarques = async () => {
      dispatch(findMarquesApi(null))
   }
   const findCarrosseries = async () => {
      dispatch(findCarrosseriesApi(null))
   }

   useEffect(() => {
      findMarques();
      findCarrosseries();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const onSubmit = async (e: SyntheticEvent) => {
      e.preventDefault();
      const response :any = await dispatch(findModelesApi(search))
      console.log("response" , response.payload)
      if(response.payload.length==0){
         ToastWarning("Aucun modèle trouvé !!");
      }else{
         ToastSuccess(`${response.payload.length} modéle(s) disponible`);

      }
   }
   return (
      <>
         {/* <!-- =-=-=-=-=-=-= Advanced Search =-=-=-=-=-=-= --> */}
         <div className="advance-search">
            <div className="section-search search-style-2">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                        {/* <!-- Nav tabs --> */}
                        <ul className="nav nav-tabs">
                           <li className="nav-item active">
                              <a className="nav-link" data-toggle="tab" href="#tab1">Lancer une nouvelle recherche</a>
                           </li>
                        </ul>
                        {/* <!-- Tab panes --> */}
                        <div className="tab-content clearfix" id="search">
                           <div className="tab-pane fade in active" id="tab1">
                              <form onSubmit={e => onSubmit(e)}>
                                 <div className="search-form pull-left">
                                    <div className="search-form-inner pull-left">
                                       <div className="col-md-3 no-padding">
                                          <div className="form-group">
                                             <label>Rechercher</label>
                                             <input type="text"
                                                onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })}
                                                name="search"
                                                value={search.search}
                                                className="form-control"
                                                placeholder="Eg Honda Civic , Audi , BMW." />
                                          </div>
                                       </div>
                                       <div className="col-md-3 no-padding">
                                          <div className="form-group">
                                             <label>Marque</label>
                                             <select className=" form-control"
                                                onChange={(e) => {setSearch({ ...search, [e.target.name]: e.target.value })}}
                                                name="marque_id"
                                                value={search.marque_id}>
                                                <option label="Choisir Marque"></option>
                                                {marques.map(marque => (<option key={marque.id} value={marque.id}>{marque.libelle}</option>))}
                                             </select>
                                          </div>
                                       </div>
                                       <div className="col-md-3 no-padding">
                                          <div className="form-group">
                                             <label>Année</label>
                                             <select className=" form-control"
                                                onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })}
                                                name="annee"
                                                value={search.annee}>
                                                <option label="Choisir Année" value=""></option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                             </select>
                                          </div>
                                       </div>
                                       <div className="col-md-3 no-padding">
                                          <div className="form-group">
                                             <label>Carrosserie</label>
                                             <select className="form-control"
                                                onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })}
                                                name="carrosserie"
                                                value={search.carrosserie}>
                                                <option label="Choisir Carrosserie"></option>
                                                {carrosseries.map(carrosserie => (<option key={carrosserie.id} value={carrosserie.id}>{carrosserie.libelle}</option>))}

                                             </select>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="form-group pull-right">
                                       <button type="submit" value="submit" className="btn btn-lg btn-theme" >
                                          <i className="fa fa-search"></i> Rechercher
                                       </button>
                                    </div>
                                 </div>
                              </form>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* <!-- =-=-=-=-=-=-= Advanced Search End =-=-=-=-=-=-= --> */}
      </>
   )
}

export default AdvancedSearch