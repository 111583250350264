import React from 'react'

const BrandsPanel = () => {
    return (
        <>
            {/* <!-- Brands Panel -->     */}
            <div className="panel panel-default">
                {/* <!-- Heading --> */}
                <div className="panel-heading" role="tab" id="headingTwo">
                    <h4 className="panel-title">
                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Brands
                        </a>
                    </h4>
                </div>
                {/* <!-- Content --> */}
                <div id="collapseTwo" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingTwo">
                    <div className="panel-body">
                        {/* <!-- Search --> */}
                        <div className="search-widget">
                            <input placeholder="Search by Car Name" type="text" />
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                        {/* <!-- Brands List -->                               */}
                        <div className="skin-minimal">
                            <ul className="list">
                                <li>
                                    <input type="checkbox" id="minimal-checkbox-1" />
                                    <label htmlFor="minimal-checkbox-1">All Brands</label>
                                </li>
                                <li>
                                    <input type="checkbox" id="minimal-checkbox-2" />
                                    <label htmlFor="minimal-checkbox-2">Audi </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="minimal-checkbox-3" />
                                    <label htmlFor="minimal-checkbox-3">BMW </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="minimal-checkbox-4" />
                                    <label htmlFor="minimal-checkbox-4">Mercedes </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="minimal-checkbox-5" />
                                    <label htmlFor="minimal-checkbox-5">Lamborghini </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="minimal-checkbox-6" />
                                    <label htmlFor="minimal-checkbox-6">Honda</label>
                                </li>
                                <li>
                                    <input type="checkbox" id="minimal-checkbox-7" />
                                    <label htmlFor="minimal-checkbox-7">Bugatti </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="minimal-checkbox-8" />
                                    <label htmlFor="minimal-checkbox-8">Acura </label>
                                </li>
                            </ul>
                            <a href=".cat_modal" data-toggle="modal" className="pull-right"><strong>View All</strong></a>
                        </div>
                        {/* <!-- Brands List End -->                  */}
                    </div>
                </div>
            </div>
            {/* <!-- Brands Panel End --> */}
        </>
    )
}

export default BrandsPanel