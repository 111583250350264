import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "../../store";
import axios from 'axios';
import { CarburantType } from '../../../Types/CarburantType';


/**
 * Get list of carburant by energie.
 * @public
 */
export const findCarburantByEnergieApi = createAsyncThunk('parametrageCarburant/findCarburantByEnergieApi', async (energie: string,{ rejectWithValue, dispatch }) => {

    try {
        const response = await axios.get('parametrageCarburant/findCarburantByEnergieApi', {params : {energie: energie}});
        return response.data;
    } catch (error: any) {
        console.log(error)
         return rejectWithValue({
            success: false,
            message: error.response.data.message
        });
    }
});


const initialState: CarburantType[]  = []

export const carburantSlice = createSlice({
    name: 'Carburant',
    initialState: initialState,
    reducers: {
        
    },
    extraReducers: builder => {
        builder.addCase(findCarburantByEnergieApi.fulfilled, (state , action) => {
            return state = action.payload;
        });
        
    }
})

// export const {  } = carburantSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const ListCarburant = (state: RootState) => state.carburant

export default carburantSlice.reducer;