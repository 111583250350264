import React from 'react'

const SortingFilter = () => {
    return (
        <>
            {/* <!-- Sorting Filters --> */}
            <div className="clearfix"></div>
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                <div className="clearfix"></div>
                <div className="listingTopFilterBar">
                    <div className="col-md-7 col-xs-12 col-sm-7 no-padding">
                        <ul className="filterAdType">
                            <li className="active"><a href="">All ads <small>(1)</small></a> </li>
                            <li className=""><a href="">Featured <small>(35)</small></a> </li>
                        </ul>
                    </div>
                    <div className="col-md-5 col-xs-12 col-sm-5 no-padding">
                        <div className="header-listing">
                            <h6>Sort by :</h6>
                            <div className="custom-select-box">
                                <select name="order" className="custom-select">
                                    <option value="0">Most popular</option>
                                    <option value="1">The latest</option>
                                    <option value="2">The best rating</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Sorting Filters End--> */}
            <div className="clearfix"></div>
        </>
    )
}

export default SortingFilter