import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../_redux/hooks'
import { ListReservations, MesReservationsApi } from '../../../_redux/Slices/reservations/reservationSlice';
import { ReservationType } from '../../../Types/Entities/ReservationType';
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work
moment.locale('fr')

const MesReservations = () => {

const dispatch = useAppDispatch();

const reservations = useAppSelector(ListReservations)
useEffect(() => {
    dispatch(MesReservationsApi(null))
}, [dispatch])
  return (
    <div className="row">
        <div className="col-ms-12">
            <ul className="list-unstyled">

                {
                    reservations && reservations.map((reservation: ReservationType, index: number) => {
                        return (
                            <li key={index}>
                                
                                <div className="well ad-listing clearfix">
                                    <div className="col-md-3 col-sm-5 col-xs-12 grid-style no-padding">
                                        {/* <!-- Image Box --> */}
                                        <div className="img-box">
                                            {
                                                reservation.modele.image && (
                                                    <img src={process.env.REACT_APP_API_PUBLIC_URL + 'modeles/' + reservation.modele.image[0].path} 
                                                    className="img-responsive" alt="" style={{ width: '100%' }} />
                                                )
                                            }
                                        <div className="total-images">
                                            <strong>
                                                {reservation.modele.image && reservation.modele.image.length} 
                                            </strong>&nbsp;
                                             photos 
                                        </div>
                                        <div className="quick-view"> 
                                            <a href="#ad-preview" data-toggle="modal" className="view-button">
                                                <i className="fa fa-search"></i>
                                            </a>
                                        </div>
                                        </div>
                                        {/* <!-- Ad Status --> */}
                                        <span className="ad-status"> Featured </span>
                                        {/* <!-- User Preview --> */}
                                        {/* <div className="user-preview">
                                        <a href="#"> 
                                            <img src="images/users/2.jpg" className="avatar avatar-small" alt="" /> 
                                        </a>
                                        </div> */}
                                    </div>
                                    <div className="col-md-9 col-sm-7 col-xs-12">
                                        {/* <!-- Ad Content--> */}
                                        <div className="row">
                                            <div className="content-area">
                                                <div className="col-md-9 col-sm-12 col-xs-12">
                                                    {/* <!-- Ad Title --> */}
                                                    <div className="row">
                                                        <div className="col-md-9 col-sm-9 col-xs-12 inherit">
                                                        <h3>
                                                            <a>Reservation : {reservation.modele.libelle}</a>
                                                        </h3>
                                                        </div>
                                                        <div className="col-md-3 col-sm-3 col-xs-12">
                                                            
                                                        </div>
                                                    </div>
                                                
                                                    {/* <!-- Ad Meta Info --> */}
                                                    <ul className="ad-meta-info">
                                                        {/* <li> <i className="fa fa-map-marker"></i><a href="#">London</a> </li> */}
                                                        <li><i className="fa fa-clock-o"></i><span className="c-blue"><b>Reservation ajouté le : </b>{moment(reservation.created_at).format('LLLL')}</span> </li>
                                                    </ul>
                                                    {
                                                        reservation.etat =='Accepte' && (
                                                            <ul className="ad-meta-info">
                                                                <li><i className="fa fa-clock-o"></i><span className="c-green"><b>Reservation Accepté le :</b> {moment(reservation.etat_date).format('LLLL')}</span> </li>
                                                            </ul>
                                                        )
                                                    }
                                                    {
                                                        reservation.token_paiement_expire_at && reservation.etat =='Accepte' && (
                                                            <ul className="ad-meta-info">
                                                                <li><i className="fa fa-clock-o"></i><span className="c-red"><b>Veuillez bien Valider et payer avant le :</b> <span className="expire_rs">{moment(reservation.token_paiement_expire_at).format('LLLL')}</span></span> </li>
                                                            </ul>
                                                        )
                                                    }
                                                    {
                                                        reservation.etat =='Refuse' && (
                                                            <ul className="ad-meta-info">
                                                                <li><i className="fa fa-clock-o"></i>Reservation Refusé le : {moment(reservation.etat_date).format('LLLL')} </li>
                                                            </ul>
                                                        )
                                                    }
                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-6 col-xs-12"><i className="fa fa-user"></i><b> Commercial :</b><span className="c-blue"> ---- Non spécifié ----</span> </div>
                                                        <div className="col-md-6 col-sm-6 col-xs-12"><i className="fa fa-user"></i><b> Chauffeur :</b> <span className="c-blue">---- Non spécifié ----</span> </div>
                                                    </div>

                                                    <ul className="ad-meta-info">
                                                        <li><i className="fa fa-clock-o"></i>Début réservation : {moment(reservation.date_debut).format('YYYY/MM/DD')} {moment(reservation.time_debut).format('hh:mm')} </li>
                                                        <li><i className="fa fa-clock-o"></i>Fin réservation: {moment(reservation.date_fin).format('YYYY/MM/DD')} {moment(reservation.time_fin).format('hh:mm')} </li>
                                                        <li><i className="fa fa-calendar"></i>Nombre de Jours : {reservation.nbr_jour} </li>
                                                    </ul>
                                                    
                                                    {/* <!-- Ad Description--> */}
                                                    <div className="ad-details">
                                                    <ul className="list-unstyled">

                                                        <li>
                                                            {/* <i className="flaticon-air-conditioner-6"></i> */}
                                                            <img src="/assets/images/clim.png" style={{ width: '25px' }}/> Climatiseur :
                                                            {reservation.modele.climatiseur ? 'Oui': 'Non'}
                                                        </li>
                                                        <li><i className="flaticon-gas-station-1"></i>{reservation.modele.energie}</li>
                                                        <li><i className="flaticon-engine-2"></i>{reservation.modele.nbr_cv} CV</li>
                                                        <li><i className="flaticon-car-door-1"></i>{reservation.modele.nbr_place} P</li>
                                                        <li><i className="flaticon-gearshift"></i>{reservation.modele.boite_vitesse}</li>
                                                        <li><i className="flaticon-vehicle-4"></i>{reservation.modele.nbr_valise} Valises</li>
                                                        <li><i className="flaticon-calendar-2"></i>Annee {reservation.modele.annee}</li>
                                                        
                                                        
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-xs-12 col-sm-12">
                                                    {/* <!-- Ad Stats --> */}
                                                    {
                                                        reservation.etat=='Accepte' && (
                                                            <div className="panel-etat c-green">
                                                                <div>Etat  : </div>
                                                                <div>{reservation.etat}</div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        reservation.etat=='Refuse' && (
                                                            <div className="panel-etat c-red">
                                                                <div>Etat  : </div>
                                                                <div>{reservation.etat}</div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        (!reservation.etat || reservation.etat=='En_Attente') && (
                                                            <div className="panel-etat c-yellow">
                                                                <div>Etat  : </div>
                                                                <div>{reservation.etat}</div>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="short-info">
                                                        <div className="info_total_payement">
                                                            <div>Options TTC  : </div>
                                                            <div>{reservation.total_options_ttc} TND</div>
                                                        </div>
                                                        <div className="info_total_payement">
                                                            <div>Lieux TTC : </div>
                                                            <div>{reservation.total_lieux_ttc} TND</div>
                                                        </div>
                                                        <div className="info_total_payement">
                                                            <div>Location TTC : </div>
                                                            <div>{reservation.total_ttc} TND</div>
                                                        </div>
                                                        <div className="info_total_payement_rs">
                                                            <div>Total TTC : </div>
                                                            <div>
                                                                <span>{reservation.total_ttc + reservation.total_options_ttc + reservation.total_lieux_ttc  } TND</span> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- Price --> */}
                                                    <div className="price"> 
                                                    </div>
                                                    {/* <!-- Ad View Button --> */}
                                                        <a className="btn btn-block btn-primary" href={`/mesreservations/${reservation.id}`} >
                                                            Détails & Paiement
                                                        </a>
                                                    </div>
                                            </div>
                                        </div>
                                        {/* <!-- Ad Content End --> */}
                                    </div>
                                </div>
                            </li> 
                        )
                    })
                }
                              
            </ul>
                     
        </div>
    </div>
  )
}

export default MesReservations
