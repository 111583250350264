import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Shared/Header'
import Footer from './Shared/Footer'
// import io from 'socket.io-client';
import socketIOClient from 'socket.io-client';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const IndexLayout = () => {

    const url : string = process.env.REACT_APP_BASEAPI_URL ? process.env.REACT_APP_BASEAPI_URL : 'https://localhost:6060' ;

    let socketio = socketIOClient(url);

    const [notification, setnotification] = useState('')
    useEffect(() => {
        socketio.on('handshake', message => {
            console.log(message)
            setnotification(message)

        })

        const  addNotification = (message: string) => {
            setnotification(message)
    
            sendNotificationToSocket(message)
        }

        addNotification('test nots')
        // console.log('i fire once');


    }, [socketio])

    function sendNotificationToSocket(notification: string) {
        socketio.emit("handshake", notification)
    }

   


    return (
        <>
            <ToastContainer />
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default IndexLayout