import React, { useState } from 'react'
import { useAppSelector } from '../../_redux/hooks';
import { selectedModele } from '../../_redux/Slices/modeles/modeleSlice';
import _ from 'lodash';
import ReservationForm from './ReservationForm';
import { tempReservation } from '../../_redux/Slices/reservations/reservationSlice';
import ReservationDetails from './ReservationDetailsForm/ReservationDetails';
import {Helmet} from "react-helmet";


const ReservationCarSection = () => {


    const modele: any = useAppSelector(selectedModele);

    // console.log('modele===============>', modele.prix_jour)
    const temp_reservation = useAppSelector(tempReservation)


    const [ShowModalRsDetails, setShowModalRsDetails] = useState(false)

    return (
        <>
            <Helmet>
                <title>{modele.libelle}</title>
                <meta charSet="UTF-8" /> 
                <meta name="keywords" content="HTML, SEO" /> 
                <meta name="description" content={modele.description} />
                <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" /> 
                <link rel="canonical" href="URL_of_preferred_page" />
                <link rel="alternate" href="URL_of_alternate_page" hrefLang="language_code" />
                <meta name="author" content="Author's Name" />

                <meta property="og:url" content="https://www.theguardian.com/news/2021/nov/09/secrets-of-top-serial-killer-expert-france-stephane-bourgoin"/>
                <meta property="article:author" content="https://www.theguardian.com/profile/scott-sayare"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image" content="https://i.guim.co.uk/img/media/cb5820e4959fe02031f64e6574bd01e7601c053e/0_0_4576_2746/master/4576.jpg?width=1200&height=630&quality=85&auto=format&fit=crop&overlay-align=bottom%2Cleft&overlay-width=100p&overlay-base64=L2ltZy9zdGF0aWMvb3ZlcmxheXMvdGctYWdlLTIwMjEucG5n&enable=upscale&s=590c196a5703d91a8c29d9c0e01f1f5a"/>
                <meta property="al:ios:url" content="gnmguardian://news/2021/nov/09/secrets-of-top-serial-killer-expert-france-stephane-bourgoin?contenttype=Article&source=applinks"/>
                <meta property="article:publisher" content="https://www.facebook.com/theguardian"/>
                <meta property="og:title" content="What lies beneath: the secrets of France’s top serial killer expert"/>
                <meta property="fb:app_id" content="180444840287"/>
                <meta property="article:modified_time" content="2024-01-02T12:11:31.000Z"/>
                <meta property="og:image:height" content="720"/>
                <meta property="og:description" content="The long read: An intrepid expert with dozens of books to his name, Stéphane Bourgoin was a bestselling author, famous in France for having interviewed more than 70 notorious murderers. Then an anonymous collective began to investigate his past"/>
                <meta property="og:type" content="article"/>
                <meta property="al:ios:app_store_id" content="409128287"/>
                <meta property="article:section" content="News"/>
                <meta property="article:published_time" content="2021-11-09T06:00:04.000Z"/>
                <meta property="article:tag" content="US crime,Crime,Death and dying"/>
                <meta property="al:ios:app_name" content="The Guardian"/>
                <meta property="og:site_name" content="the Guardian"/>

                <meta name="twitter:dnt" content="on" />
                <meta name="twitter:app:id:iphone" content="409128287"/>
                <meta name="twitter:app:name:googleplay" content="The Guardian"/>
                <meta name="twitter:app:name:ipad" content="The Guardian"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:app:name:iphone" content="The Guardian"/>
                <meta name="twitter:app:id:ipad" content="409128287"/>
                <meta name="twitter:app:id:googleplay" content="com.guardian"/>
                <meta name="twitter:app:url:googleplay" content="guardian://www.theguardian.com/news/2021/nov/09/secrets-of-top-serial-killer-expert-france-stephane-bourgoin"/>
                <meta name="twitter:app:url:iphone" content="gnmguardian://news/2021/nov/09/secrets-of-top-serial-killer-expert-france-stephane-bourgoin?contenttype=Article&source=twitter"/>
                <meta name="twitter:image" content="https://i.guim.co.uk/img/media/cb5820e4959fe02031f64e6574bd01e7601c053e/0_0_4576_2746/master/4576.jpg?width=1200&height=630&quality=85&auto=format&fit=crop&overlay-align=bottom%2Cleft&overlay-width=100p&overlay-base64=L2ltZy9zdGF0aWMvb3ZlcmxheXMvdGctYWdlLTIwMjEucG5n&s=072ca423bb2e6560082cb6b2b0a048cb"/>
                <meta name="twitter:site" content="@guardian"/>
                <meta name="twitter:app:url:ipad" content="gnmguardian://news/2021/nov/09/secrets-of-top-serial-killer-expert-france-stephane-bourgoin?contenttype=Article&source=twitter"/>   
            </Helmet>
            {
                !_.isEmpty(modele) ? (
                    <section className="search-result-item">
                        <a className="image-link" href="#">
                            <img className="image center-block" style={{ marginTop:"20px" }} alt="" 
                                 src={process.env.REACT_APP_API_PUBLIC_URL + 'modeles/' + modele?.image[0]?.path} />
                        </a>
                        <div className="search-result-item-body">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <h4 className="search-result-item-heading"><a href="#">{modele?.libelle}</a></h4>
                                </div>
                                <div className="col-md-3">
                                    <div className="ad-details content-area">
                                        <div className="short-info">
                                            {
                                                modele.categorie.caution && (<div className="ad-stats hidden-xs"><span>Caution : </span>{modele.categorie.caution} TND</div>)
                                            }
                                        <div className="ad-stats hidden-xs"><span>Marque : </span>{modele.marque.libelle}</div>
                                            <div className="ad-stats hidden-xs"><span>Carrosserie : </span>{modele.carrosserie.libelle}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 col-sm-12 col-xs-12">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-3 col-xs-12 pointer p-5" onClick={e => setShowModalRsDetails(true)}>
                                            <div className="user-stats">
                                                <h3>{temp_reservation.total_options.total_ttc} TND</h3>
                                                <small>Options</small>
                                            </div>
                                        </div>
                                        {
                                            _.isUndefined(modele.prix_jour) || modele.prix_jour.length == 0 ? (
                                                <div className="col-md-3 col-sm-3 col-xs-12 pointer p-5" onClick={e => setShowModalRsDetails(true)}>
                                                    <div className="user-stats">
                                                        <h3>{modele.prix_jour[0]?.prix_ttc}</h3>
                                                        <small style={{ color: "red" }}>Prix jour Indisponible</small>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                        {
                                            modele.prix_jour.length == 1 ? (
                                                <div className="col-md-3 col-sm-3 col-xs-12 pointer p-5" onClick={e => setShowModalRsDetails(true)}>
                                                    <div className="user-stats">
                                                        <h3>{modele.prix_jour[0].prix_ttc} TND</h3>
                                                        <small>Prix par jour</small>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                        {
                                            modele.prix_jour.length > 1 ? (
                                                <div className="col-md-3 col-sm-3 col-xs-12 pointer p-5" onClick={e => setShowModalRsDetails(true)}>
                                                    <div className="user-stats">
                                                        <h3>{modele.prix_jour[modele.prix_jour.length - 1].prix_ttc} - { modele.prix_jour[0].prix_ttc } TND</h3>
                                                        <small>Prix par jour</small>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                        

                                        <div className="col-md-3 col-sm-3 col-xs-12 pointer p-5" onClick={e => setShowModalRsDetails(true)}>
                                            <div className="user-stats">
                                                <h3>{temp_reservation.numberOfDays}</h3>
                                                <small>Nbr de Jours</small>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-xs-12 pointer p-5" onClick={e => setShowModalRsDetails(true)}>
                                            <div className="user-stats">
                                                <h3>{temp_reservation.total_options.total_ttc +  temp_reservation.total_location.total_ttc} TND</h3>
                                                <small>Total TTC</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="ad-listing  ">
                                    <div className="content-area">
                                        <div className="ad-details">
                                            <ul className="list-unstyled">
                                                <li><i className="flaticon-gas-station-1"></i>{modele.energie}</li>
                                                <li>
                                                    {/* <i className="flaticon-air-conditioner-6"></i> */}
                                                    <img src="/assets/images/clim.png" style={{ width: '25px' }}/> Climatiseur :
                                                    {modele.climatiseur ? 'Oui': 'Non'}
                                                </li>
                                                <li><i className="flaticon-engine-2"></i>{modele.nbr_cv} CV</li>
                                                <li><i className="flaticon-car-door-1"></i>{modele.nbr_place} P</li>
                                                <li><i className="flaticon-gearshift"></i>{modele.boite_vitesse}</li>
                                                <li><i className="flaticon-vehicle-4"></i>{modele.nbr_valise} Valises</li>
                                                <li><i className="flaticon-calendar-2"></i>Annee {modele.annee}</li>
                                                
                                                <li className="text-center both-on-mobile pull-right">
                                                    <a className="btn btn-block btn-warning" onClick={e => setShowModalRsDetails(true)} >
                                                        détails Reservation
                                                    </a>
                                                </li>

                                                <li className="text-center both-on-mobile pull-right">
                                                    <a className="btn btn-block btn-primary" href={modele.url} target="_blank">
                                                        détails Voiture
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ReservationForm/>
                        <ReservationDetails  ShowModal={ShowModalRsDetails} SetShowModal={setShowModalRsDetails}/>
                    </section>
                ) : null
            }

        </>
    )
}

export default ReservationCarSection