import React from 'react'

const Banner = () => {
   return (
      <div id="banner">
         <div className="container">
            <div className="search-container">
               {/* <!-- Form --> */}
               <h2>Besoin d'une voiture ?</h2>
               <h2>Nous sommes une référence dans la locations de voitures.</h2>
               <p>Plus de <strong>15</strong> Voitures -<strong> Disponible </strong> pour vous.</p>
               <p>Nos véhicules sont disponibles 24 h/24 et 7 j/7 dans tout Tunis grâce à nos nombreuses stations. Le plus compliqué sera de faire votre choix parmi nos véhicules. </p>

               <a className="btn btn-theme btn-reserve-now" href="#search">Reserver maintenant</a>
               <div className="row">
                  <div className="col-md-4 col-sm-4 col-xs-12 "></div>
                  <div className="col-md-4 col-sm-4 col-xs-12 "></div>
                  <div className="col-md-4 col-sm-4 col-xs-12 text-center block-apps" style={{padding: "20px",background: "#ffffff54"}} >
                     <a href="#" style={{ marginRight: "30px", marginTop: "10px" }} >
                        <img src="/assets/images/googleplay.png" alt="" style={{marginTop: "5px"}} />
                     </a>
                     <a href="#" style={{ marginRight: "30px", marginTop: "10px" }} >
                        <img src="/assets/images/appstore.png" alt="" style={{marginTop: "5px"}} />
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Banner