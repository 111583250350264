import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react'


const CardFeatured = ({item}: any) => {

    const [IntervalPrix, setIntervalPrix] = useState([]) as any
    useEffect(() => {
        let current_date = moment();
        var prix_jour = _.filter(item.categorie.Parametrage_prix, prix => moment(prix.date_debut)<= current_date && moment(prix.date_fin) >= current_date )
        prix_jour = prix_jour.length > 0 ? _.orderBy(prix_jour, ['nbr_jour'], ['asc']) : [];
        setIntervalPrix(prix_jour)
    }, [item])
    
    return (
        <div className="col-md-4 col-xs-12 col-sm-6">
            <div className="category-grid-box-1">
                <div className="featured-ribbon">
                    <span>Disponible</span>
                </div>
                <div className="image" style={{ height: "300px" }}>
                    
                        {
                            item.image.length ? (
                                <img alt="Carspot" src={process.env.REACT_APP_API_PUBLIC_URL + 'modeles/' + item.image[0].path } className="img-responsive"  
                                    style={{ maxHeight: "300px" }}/>
                            ) : ''
                        }
                    <div className="ribbon popular"></div>
                    <div className="price-tag">
                        <div className="price">
                            {
                                IntervalPrix.length==1 ? (<span>{IntervalPrix[0].prix_ttc} TND</span>) : ''
                            } 
                            {
                                IntervalPrix.length > 1 ? (<span>{IntervalPrix[IntervalPrix.length - 1].prix_ttc} - {IntervalPrix[0].prix_ttc} TND</span>) : ''
                            } 
                        </div>
                    </div>
                </div>
                <div className="short-description-1 clearfix">
                    <div className="category-title"> <span><a href="#">{item.marque.libelle}</a></span> </div>
                    <h3><a title="" href={item.url} target="_blank" rel="noreferrer">{item.libelle} </a></h3>
                    <p className="location"><i className="fa fa-map-marker"></i> Tunis</p>
                    <ul className="list-unstyled">
                        <li><a href="#" onClick={e => e.preventDefault()}><i className="flaticon-gas-station-1"></i>{item.energie}</a></li>
                        <li><a href="#" onClick={e => e.preventDefault()}><i className="flaticon-air-conditioner-2"></i>{item.climatiseur ? 'Oui': 'Non'}</a></li>
                        <li><a href="#" onClick={e => e.preventDefault()}><i className="flaticon-engine-2"></i>{item.nbr_cv} CV</a></li>
                        <li><a href="#" onClick={e => e.preventDefault()}><i className="flaticon-car-door-1"></i>{item.nbr_place} P</a></li>
                        {/* <li><a href="#" onClick={e => e.preventDefault()}><i className="flaticon-vehicle-4"></i>{item.nbr_valise} Valise</a></li> */}
                        <li><a href="#" onClick={e => e.preventDefault()}><i className="flaticon-gearshift"></i>{item.boite_vitesse} </a></li>
                        <li><a href="#" onClick={e => e.preventDefault()}><i className="flaticon-car-2"></i>{item.carrosserie.libelle}</a></li>
                        <li><a href="#" onClick={e => e.preventDefault()}><i className="flaticon-calendar-2"></i>{item.annee} </a></li>
                    </ul>
                </div>
                <div className="ad-info-1">
                    <p><i className="flaticon-calendar"></i> &nbsp;<span>Disponible</span> </p>
                    <ul className="pull-right">
                        <li title="details"> 
                            <a href={item.url} target='_blank' rel="noreferrer" title="Details"><i className="flaticon-tool"></i></a> 
                        </li>
                        <li style={{ width: "180px" }}> 
                            <a className="btn btn-xs btn-success btn-reserver"  href={`/cars/${item.id}`} target='_blank' rel="noreferrer">
                                <i className="flaticon-message" title="Reserver" style={{ color: "#fff" }}></i> Reserver
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {/* <!-- Listing Ad Grid --> */}
        </div>
    )
}

export default CardFeatured