import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../_redux/hooks';
import { Reservation, ReservationByIdApi } from '../../../_redux/Slices/reservations/reservationSlice';
import moment from 'moment';

const ReservationPaiementForm = () => {

  const params = useParams();

  const dispatch = useAppDispatch();

  const [type_paiement, setTypePaiement] = useState('A_la_livraison')
  const reservation = useAppSelector(Reservation)
  useEffect(() => {
      dispatch(ReservationByIdApi(Number(params.id)))
  }, [dispatch, params])
  return (
    <div className="row">
        <div className="col-md-12">
            <div className="panel panel-body">
                <div className="panel-heading">Paiement</div>
                <div className="panel-">
                    <form action="">
                        <div className="row">
                            <div className="col-md-8 col-sm-8 col-xs-12">
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Copie Cin</label>
                                        <input type="file" className='form-control' />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Copie Permis</label>
                                        <input type="file" className='form-control' />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Copie Passeport</label>
                                        <input type="file" className='form-control' />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='text-center bt-ddd mt-20'>
                                            Si vous avez un deuxième conducteur avec vous, Merci de remplier ce formulaire <b>(les champs non obligatoire)</b>
                                        </p>
                                        <h3 className="text-center td-u "><b>Deuxième Conducteur</b></h3>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Nom</label>
                                                <input type="text" className='form-control' placeholder='Nom' />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Prénom</label>
                                                <input type="text" className='form-control' placeholder='Prénom' />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Email</label>
                                                <input type="text" className='form-control' placeholder='Email' />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Mobile</label>
                                                <input type="text" className='form-control'  placeholder='Mobile'  />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Téléphone</label>
                                                <input type="text" className='form-control' placeholder='Téléphone' />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Date Permis</label>
                                                <input type="text" className='form-control' placeholder='Date Permis' />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Copie Cin</label>
                                                <input type="file" className='form-control'  placeholder='Copie Cin'  />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Copie Permis</label>
                                                <input type="file" className='form-control' placeholder='Copie Permis' />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Copie Passeport</label>
                                                <input type="file" className='form-control' placeholder='Copie Passeport' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12 panel-payement-method">
                                {
                                    reservation && (
                                        <div className="short-info info-payement-block">
                                            <div className="info_total_payement">
                                                <div>Options TTC  : </div>
                                                <div>{reservation.total_options_ttc} TND</div>
                                            </div>
                                            <div className="info_total_payement">
                                                <div>Lieux TTC : </div>
                                                <div>{reservation.total_lieux_ttc} TND</div>
                                            </div>
                                            <div className="info_total_payement">
                                                <div>Location TTC : </div>
                                                <div>{reservation.total_ttc} TND</div>
                                            </div>
                                            <div className="info_total_payement_rs">
                                                <div>Total TTC : </div>
                                                <div>
                                                    <span>{reservation.total_ttc + reservation.total_options_ttc + reservation.total_lieux_ttc  } TND</span> 
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            <div className="">
                                <hr style={{ margin: "18px 25px 6px 25px" }}/>
                                <div className="form-group">
                                    <label htmlFor="">Paiement :</label>
                                    <br />
                                    <label htmlFor="A_la_livraison" className='label_payement'>
                                        <input type="radio" 
                                                name="type_paiement" 
                                                value="A_la_livraison" 
                                                defaultChecked={ type_paiement === 'A_la_livraison'} 
                                                className='form-control' 
                                                onClick={e => setTypePaiement('A_la_livraison')}
                                                id="A_la_livraison" /> <b>A la Livraison</b>
                                    </label>
                            
                                    <label htmlFor="20%_En_ligne" className='label_payement'>
                                        <input type="radio" 
                                                name="type_paiement" 
                                                value="20%_En_ligne" 
                                                defaultChecked={ type_paiement === '20%_En_ligne'} 
                                                className='form-control' 
                                                onClick={e => setTypePaiement('20%_En_ligne')}
                                                id="20%_En_ligne"/> <b>20% En ligne</b>
                                    </label>
                                
                                    <label htmlFor="100%_En_ligne" className='label_payement'>
                                        <input type="radio" 
                                                name="type_paiement" 
                                                value="20%_En_ligne" 
                                                defaultChecked={ type_paiement === '20%_En_ligne'} 
                                                className='form-control'
                                                onClick={e => setTypePaiement('20%_En_ligne')} 
                                                id="100%_En_ligne"/> <b>100% En ligne</b>
                                    </label>
                                </div>
                                <hr />
                                    {
                                        type_paiement === 'A_la_livraison' && <p className='text-center'><b>Paiement en espèces à la livraison.</b></p> 
                                    }

                                    {
                                        (type_paiement === '20%_En_ligne' || type_paiement === '100%_En_ligne' ) && (
                                        <div className="row">
                                            <div className='col-md-12'>
                                                <div className="form-group relative">
                                                    <label htmlFor="" className='w-100'>
                                                        Numero de la carte <span className='c-red'>*</span>
                                                        <span className="pull-right">
                                                        <img src="/assets/images/paiement/mastercard.png" alt="Mastercard" />
                                                        <img src="/assets/images/paiement/visa.png" alt="Visa" />
                                                        <img src="/assets/images/paiement/amex.png" alt="Amex" />
                                                        <img src="/assets/images/paiement/poste_logo.jpeg" alt="Poste Tunisienne" style={{ width: "45px" }} />
                                                        </span>
                                                    </label>
                                                    <input type="number" name="numero_carte" id="numero_carte" className="form-control" placeholder="Numero de la carte"/>
                                                    <img src="/assets/images/paiement/mastercard-logo.png" className="img_logo_numero" alt="Numero carte" />

                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form-group relative">
                                                    <label htmlFor="">Date d'expiration <span className='c-red'>*</span></label>
                                                    <input type="text" className='form-control' />
                                                    <i className="fa fa-calendar icon_calendar" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group relative">
                                                    <label htmlFor="">CVV <span className='c-red'>*</span></label>
                                                    <input type="text" className='form-control' />
                                                    <i className="fa fa-lock icon_lock" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) 
                                    }
                                </div>
                            </div>
                        </div>
                        
                        
                        
                        
                        <div className="form-group">
                            <button className="btn btn-primary btn-block">
                                <i className="fa fa-save"></i> 
                                Payer {reservation && 
                                (<span className='chiffre_ttc hide-xs'>{reservation.total_ttc + reservation.total_options_ttc + reservation.total_lieux_ttc  } TND</span>)} & Envoyer Email</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      <div className="col-md-12">
          {
            reservation && (
              <div className="well ad-listing clearfix">
                    <div className="col-md-3 col-sm-5 col-xs-12 grid-style no-padding">
                        {/* <!-- Image Box --> */}
                        <div className="img-box">
                            {
                                reservation.modele.image && (
                                    <img src={process.env.REACT_APP_API_PUBLIC_URL + 'modeles/' + reservation.modele.image[0].path} 
                                    className="img-responsive" alt="" style={{ width: '100%' }} />
                                )
                            }
                        <div className="total-images">
                            <strong>
                                {reservation.modele.image && reservation.modele.image.length} 
                            </strong>&nbsp;
                              photos 
                        </div>
                        <div className="quick-view"> 
                            <a href="#ad-preview" data-toggle="modal" className="view-button">
                                <i className="fa fa-search"></i>
                            </a>
                        </div>
                        </div>
                        {/* <!-- Ad Status --> */}
                        <span className="ad-status"> Featured </span>
                        {/* <!-- User Preview --> */}
                        {/* <div className="user-preview">
                        <a href="#"> 
                            <img src="images/users/2.jpg" className="avatar avatar-small" alt="" /> 
                        </a>
                        </div> */}
                    </div>
                    <div className="col-md-9 col-sm-7 col-xs-12">
                        {/* <!-- Ad Content--> */}
                        <div className="row">
                            <div className="content-area">
                                <div className="col-md-9 col-sm-12 col-xs-12">
                                    {/* <!-- Ad Title --> */}
                                    <div className="row">
                                        <div className="col-md-9 col-sm-9 col-xs-12 inherit">
                                        <h3>
                                            <a>Reservation : {reservation.modele.libelle}</a>
                                        </h3>
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-xs-12">
                                            
                                        </div>
                                    </div>
                                
                                    {/* <!-- Ad Meta Info --> */}
                                    <ul className="ad-meta-info">
                                        {/* <li> <i className="fa fa-map-marker"></i><a href="#">London</a> </li> */}
                                        <li><i className="fa fa-clock-o"></i><span className="c-blue"><b>Reservation ajouté le : </b>{moment(reservation.created_at).format('LLLL')}</span> </li>
                                    </ul>
                                    {
                                        reservation.etat =='Accepte' && (
                                            <ul className="ad-meta-info">
                                                <li><i className="fa fa-clock-o"></i><span className="c-green"><b>Reservation Accepté le :</b> {moment(reservation.etat_date).format('LLLL')}</span> </li>
                                            </ul>
                                        )
                                    }
                                    {
                                        reservation.token_paiement_expire_at && reservation.etat =='Accepte' && (
                                            <ul className="ad-meta-info">
                                                <li><i className="fa fa-clock-o"></i><span className="c-red"><b>Veuillez bien Valider et payer avant le :</b> <span className="expire_rs">{moment(reservation.token_paiement_expire_at).format('LLLL')}</span></span> </li>
                                            </ul>
                                        )
                                    }
                                    {
                                        reservation.etat =='Refuse' && (
                                            <ul className="ad-meta-info">
                                                <li><i className="fa fa-clock-o"></i>Reservation Refusé le : {moment(reservation.etat_date).format('LLLL')} </li>
                                            </ul>
                                        )
                                    }
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-12"><i className="fa fa-user"></i><b> Commercial :</b><span className="c-blue"> ---- Non spécifié ----</span> </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12"><i className="fa fa-user"></i><b> Chauffeur :</b> <span className="c-blue">---- Non spécifié ----</span> </div>
                                    </div>

                                    <ul className="ad-meta-info">
                                        <li><i className="fa fa-clock-o"></i>Début réservation : {moment(reservation.date_debut).format('YYYY/MM/DD')} {moment(reservation.time_debut).format('hh:mm')} </li>
                                        <li><i className="fa fa-clock-o"></i>Fin réservation: {moment(reservation.date_fin).format('YYYY/MM/DD')} {moment(reservation.time_fin).format('hh:mm')} </li>
                                        <li><i className="fa fa-calendar"></i>Nombre de Jours : {reservation.nbr_jour} </li>
                                    </ul>
                                    
                                    {/* <!-- Ad Description--> */}
                                    <div className="ad-details">
                                    <ul className="list-unstyled">

                                        <li>
                                            {/* <i className="flaticon-air-conditioner-6"></i> */}
                                            <img src="/assets/images/clim.png" style={{ width: '25px' }}/> Climatiseur :
                                            {reservation.modele.climatiseur ? 'Oui': 'Non'}
                                        </li>
                                        <li><i className="flaticon-gas-station-1"></i>{reservation.modele.energie}</li>
                                        <li><i className="flaticon-engine-2"></i>{reservation.modele.nbr_cv} CV</li>
                                        <li><i className="flaticon-car-door-1"></i>{reservation.modele.nbr_place} P</li>
                                        <li><i className="flaticon-gearshift"></i>{reservation.modele.boite_vitesse}</li>
                                        <li><i className="flaticon-vehicle-4"></i>{reservation.modele.nbr_valise} Valises</li>
                                        <li><i className="flaticon-calendar-2"></i>Annee {reservation.modele.annee}</li>
                                        
                                        
                                    </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-12 col-sm-12">
                                    {/* <!-- Ad Stats --> */}
                                    {
                                        reservation.etat=='Accepte' && (
                                            <div className="panel-etat c-green">
                                                <div>Etat  : </div>
                                                <div>{reservation.etat}</div>
                                            </div>
                                        )
                                    }
                                    {
                                        reservation.etat=='Refuse' && (
                                            <div className="panel-etat c-red">
                                                <div>Etat  : </div>
                                                <div>{reservation.etat}</div>
                                            </div>
                                        )
                                    }
                                    {
                                        (!reservation.etat || reservation.etat=='En_Attente') && (
                                            <div className="panel-etat c-yellow">
                                                <div>Etat  : </div>
                                                <div>{reservation.etat}</div>
                                            </div>
                                        )
                                    }
                                    <div className="short-info">
                                        <div className="info_total_payement">
                                            <div>Options TTC  : </div>
                                            <div>{reservation.total_options_ttc} TND</div>
                                        </div>
                                        <div className="info_total_payement">
                                            <div>Lieux TTC : </div>
                                            <div>{reservation.total_lieux_ttc} TND</div>
                                        </div>
                                        <div className="info_total_payement">
                                            <div>Location TTC : </div>
                                            <div>{reservation.total_ttc} TND</div>
                                        </div>
                                        <div className="info_total_payement_rs">
                                            <div>Total TTC : </div>
                                            <div>
                                                <span>{reservation.total_ttc + reservation.total_options_ttc + reservation.total_lieux_ttc  } TND</span> 
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Price --> */}
                                    <div className="price"> 
                                    </div>
                                   
                                    </div>
                            </div>
                        </div>
                        {/* <!-- Ad Content End --> */}
                    </div>
              </div>
            )
          }
      </div>

      <div className="col-md-12">
          <div className="panel panel-body">
              <div className="panel-heading">Options</div>
                  <div className="panel-body">
                      
                  </div>
          </div>

          <div className="panel panel-body">
              <div className="panel-heading">Lieux</div>
                  <div className="panel-body">
                      
                  </div>
          </div>


      </div>
      
    </div>
  )
}

export default ReservationPaiementForm
