import {  combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import authSlice from './Slices/auth/authSlice';
import thunk from 'redux-thunk';
import marqueSlice from './Slices/marques/marqueSlice';
import carrosserieSlice from './Slices/carrosseries/carrosserieSlice';
import modeleSlice from './Slices/modeles/modeleSlice';
import optionSlice from './Slices/options/optionSlice';
import reservationSlice from './Slices/reservations/reservationSlice';
import lieuxSlice from './Slices/lieux/lieuxSlice';
import carburantSlice from './Slices/carburant/carburantSlice';



const persistConfig = {
    key: process.env.REACT_APP_STORAGE_KEYNAME ? process.env.REACT_APP_STORAGE_KEYNAME : 'xcars-allo-rents',
    storage,
    whitelist: ['auth']
}

const rootReducer = combineReducers({
    auth: authSlice,
    marques : marqueSlice,
    lieux : lieuxSlice,
    carrosseries: carrosserieSlice,
    modeles: modeleSlice,
    options: optionSlice,
    reservations: reservationSlice,
    carburant: carburantSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)
// console.log('process.env.REACT_APP_NODE_ENV ', process.env.REACT_APP_NODE_ENV )
const store = configureStore({
    reducer: persistedReducer,

    devTools: process.env.REACT_APP_NODE_ENV !== 'production',
    middleware: [thunk]
    //middleware: [applyMiddleware(thunk), curryGetDefaultMiddleware()]

})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
