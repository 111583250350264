import React from 'react'
import TopBar from './TopBar'
import NavigationMenu from './NavigationMenu'

const Header = () => {
  return (
    <>
        {/* <!-- =-=-=-=-=-=-= Main Header =-=-=-=-=-=-= --> */}
      <div className="colored-header">
         {/* <!-- Top Bar --> */}
         <TopBar />
         {/* <!-- Top Bar End --> */}
         {/* <!-- Navigation Menu --> */}
         <div className="clearfix"></div>
         {/* <!-- menu start --> */}
            <NavigationMenu />
         {/* <!-- menu end --> */}
      </div>
      {/* <!-- =-=-=-=-=-=-= Main Header End  =-=-=-=-=-=-= --> */}
    
    </>
  )
}

export default Header