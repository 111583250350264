import { LocalizationProvider, MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css'; 
import React, { createRef, useEffect, useRef, useState } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { frFR } from '@mui/x-date-pickers/locales';
import { frFR as dataGridFrFR } from '@mui/x-data-grid';
import { frFR as coreFrFR } from '@mui/material/locale';
import { fr } from 'date-fns/locale'
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { ReservationSchema } from '../../Schemas/ReservationSchema';
import { ReservationDataType } from '../../Types/ReservationDataType';
import IntlTelInput, { CountryData } from 'react-intl-tel-input-18';
import { useAppDispatch, useAppSelector } from '../../_redux/hooks';
import { ListOptions, findOptionsApi, setPrixCarburantOnOption } from '../../_redux/Slices/options/optionSlice';
import _ from 'lodash';
import moment from 'moment';
import { addReservationApi, changeDates, changeListPrixParJour, changeListSelectedOptions, changeNumberOfDays, changeTotalLocation, changeTotalOptions, tempReservation } from '../../_redux/Slices/reservations/reservationSlice';
import { changePrixJour, selectedModele } from '../../_redux/Slices/modeles/modeleSlice';
import { ToastSuccess } from '../Toasts/ToastSuccess';
import { ToastWarning } from '../Toasts/ToastWarning';
import { isLoggedIn } from '../../_redux/Slices/auth/authSlice';
import { UserConnected } from '../../_redux/Slices/auth/authSlice';
import { TextField } from '@mui/material';
import { ListLieux, findLieuxApi } from '../../_redux/Slices/lieux/lieuxSlice';
import OptionType from '../../Types/OptionType';
import { ListCarburant, findCarburantByEnergieApi } from '../../_redux/Slices/carburant/carburantSlice';
import Modele from '../../Types/Modele';
import { CarburantType } from '../../Types/CarburantType';
import ReservationCarburant from './ReservationDetailsForm/ReservationCarburant';
import { getAllDatetimesBetween } from '../../Helpers/Helpers';

const theme = createTheme({
    typography: {
        fontSize: 25,
    },

}, frFR,
    dataGridFrFR, // x-data-grid translations
    coreFrFR // core translations;
)
const ReservationForm = () => {
    // navigate to redirect user after submit form
    const navigate = useNavigate();

    //dispatch
    const dispatch = useAppDispatch();

    //get list of options from redux
    const options = useAppSelector(ListOptions);
    const selected_model : Modele = useAppSelector(selectedModele);
    const temp_reservation = useAppSelector(tempReservation)
    /**
     * @description check if user connected
     */
    const [user, setUser] = useState({} as any)

    const [MinimumHourForDay, setMinimumHourForDay] = useState(12)

    const [ShowModalCarburant, setShowModalCarburant] = useState(false)
    const loggedIn: boolean = useAppSelector(isLoggedIn);
    const current_user = useAppSelector(UserConnected)
    const lieux = useAppSelector(ListLieux)

    //add validation to Form-hooks
    const formOptions = { resolver: yupResolver(ReservationSchema) };
    const { register, handleSubmit, formState, setValue, reset, control, watch } = useForm<ReservationDataType>(formOptions);
    const { errors } = formState;


    useEffect(() => {
        if (current_user) {
            setUser(current_user)
        }

    }, [loggedIn])


    // carburant prix for Option carburant
    const carburant: CarburantType[] | undefined = useAppSelector(ListCarburant);
    useEffect(() => {
      dispatch(findCarburantByEnergieApi(selected_model.energie))
    }, [selected_model])
    
    // change prix carburant on option
    useEffect(() => {
      if(carburant && carburant.length && options) {

        dispatch(setPrixCarburantOnOption(carburant[0]))
      }
    }, [carburant])
    


    const InitalFormReservation = () => {
        if (user) {
            reset({
                modele_id: selected_model.id,
                date_debut: new Date(),
                date_fin: new Date(Date.now() + (3600 * 1000 * 24)),
                heure_debut: null,
                heure_fin: null,
                lieux_priseencharge_id: '',
                lieux_restitution_id: '',
                user_id: user.id ? user.id : null,
                civilite: user.civilite ? user.civilite : '',
                nom: user.nom ? user.nom : '',
                prenom: user.prenom ? user.prenom : '',
                mobile: {
                    status: true,
                    phoneNumber: user.mobile,
                    country: {
                        name: user.country_mobile,
                        iso2: user.iso2_mobile,
                        dialCode: user.dial_code_mobile,
                        priority: 0,
                        areaCodes: null
                    }
                },
                telephone: {
                    status: true,
                    phoneNumber: user.telephone,
                    country: {
                        name: user.country_telephone,
                        iso2: user.iso2_telephone,
                        dialCode: user.dial_code_telephone,
                        priority: 0,
                        areaCodes: null
                    }
                },
                email: user.email ? user.email : '',
                age: user.age ? user.age : '',
                date_permis: user.date_permis ? new Date(user.date_permis) : null,
                adresse: user.adresse ? user.adresse : '',
                vol: false,
                numero_vol: '',
                remarque: '',
                condition: false,
            });
        } else {
            reset({
                modele_id: selected_model.id,
                date_debut: new Date(),
                date_fin: new Date(Date.now() + (3600 * 1000 * 24)),
                heure_debut: null,
                heure_fin: null,
                lieux_priseencharge_id: '',
                lieux_restitution_id: '',
                user_id: null,
                civilite: '',
                nom: '',
                prenom: '',
                mobile: {
                    status: false,
                    phoneNumber: '',
                    country: {
                        name: '',
                        iso2:'',
                        dialCode: '',
                        priority: 0,
                        areaCodes: null
                    }
                },
                telephone: {
                    status: false,
                    phoneNumber: '',
                    country: {
                        name: '',
                        iso2: '',
                        dialCode: '',
                        priority: 0,
                        areaCodes: null
                    }
                },
                email: '',
                age: '',
                date_permis: null,
                adresse: '',
                vol: false,
                numero_vol: '',
                remarque: '',
                condition: false,

            });
        }
    }
    /**
    * @desc initialisation de form-hooks
    */
    useEffect(() => {

        dispatch(changeListSelectedOptions([]))
        dispatch(changeNumberOfDays(1))

        dispatch(findOptionsApi(null))
        dispatch(findLieuxApi(null))
        InitalFormReservation();


    }, [user])

    /**
     * @desc update mobile when change mobile field
     * @param status {string}
     * @param phoneNumber {string}
     * @param country {CountryData}
     */
    const handlePhoneChange = (status: boolean, phoneNumber: string, country: CountryData) => {
        setValue('mobile', {
            status,
            phoneNumber,
            country
        })
    };




    /**
     * watch Interval 
     */
    const date_debut = watch('date_debut')
    const date_fin = watch('date_fin')
    const heure_debut = watch('heure_debut')
    const heure_fin = watch('heure_fin')
    const vol = watch('vol')

    /**
     * @desc recalculate number of days in INTERVAL SELECTED
     */
    const recalculateNbreJours = async () => {
        // let NumberOfDays = 1

        //check if chauffeur  is selected or not
        var chauffeur_option = _.filter(temp_reservation.listSelectedOptions, (option :OptionType)=> option.type_option=="CHAUFFEUR")

        var chauffeur = chauffeur_option.length>0 ? true : false;

        if (date_debut && date_fin) {

            const start_date = moment(date_debut).format("YYYY-MM-DD");
            const end_date = moment(date_fin).format("YYYY-MM-DD");

            // get hours and minute from date heure_debut and heure_fin
            const start_time = heure_debut ? moment(heure_debut).format("HH:mm:ss") : '';
            const end_time = heure_fin ? moment(heure_fin).format("HH:mm:ss") : '';

            const first_datetime = start_time && end_time ? moment(`${start_date} ${start_time}`) : moment(`${start_date}`);
            const last_datetime = start_time && end_time ? moment(`${end_date} ${end_time}`) : moment(`${end_date}`);
            // format start tdatetime and enddatime
            if(!chauffeur){
                // if without chauffeur calculate time
                const NumberOfHours = last_datetime.diff(first_datetime, 'hour');
                const RestOfHours = NumberOfHours % 24;

                var NumberOfDays = last_datetime.diff(first_datetime, 'days');
    
                if(RestOfHours >= MinimumHourForDay) {
                    NumberOfDays++;
                }
            }else{
                // with chauffeur  calcul location per day and do not calculate times
                var NumberOfDays = moment.duration(moment(date_fin, "YYYY-MM-DD")
                                         .diff(moment(date_debut, "YYYY-MM-DD")))
                                         .asDays();
                NumberOfDays =Math.round(NumberOfDays)+1
            }
            
            /**
             * check if date fin supérieur a date début
             */
            if(NumberOfDays <=0) {
                ToastWarning("Veuillez bien vérifier La date début et la date fin!")
            }else{

                //update number of days
                dispatch(changeNumberOfDays(NumberOfDays))

                /**
                 * get all days from interval
                 */
                
                const dates: string[] = getAllDatetimesBetween(first_datetime, last_datetime);
               
                dispatch(changeDates(dates))

                // console.log('dates => ', dates)
                /**
                 * Calculate total per day
                 */

                // console.log('selected_model.list_prix.length => ', selected_model.list_prix.length)

                if(selected_model.list_prix.length){

                    var list_prix_par_jour: any = {};

                    // console.log("dates", dates);
                    await dates.map(async (date: string, index: number) => {

                        const filtred_prix = await selected_model.list_prix.filter((prix: any) => {
    
                            let date_debut = moment(prix.date_debut);
                            let date_fin = moment(prix.date_fin);
                            let date_location = moment(date);
    
                            // console.log(index, date_debut, date_fin, date_location, NumberOfDays, prix.nbr_jour)
                            if (prix.nbr_jour <= Number(NumberOfDays) &&
                                date_location >= date_debut &&
                                date_location <= date_fin) {
                                    // console.log('obj', true)
                                return true;
                            } else {
                                // console.log('obj', false)
                                return false;
                            }
                        });
                        // console.log('filtred_prix', filtred_prix)
    

                        if (filtred_prix.length > 0) {

                            let res_filter = _.orderBy(filtred_prix, ['nbr_jour'], ['desc'])

                            //recheck if nombre de jour equals to dates.length
                            if(NumberOfDays==dates.length){
                                list_prix_par_jour[date.toString()] = res_filter[0]
                            }else{
                                //if dates.length>NumberOfDays make the first day free
                                if(index==0){
                                    list_prix_par_jour[date.toString()] = {...res_filter[0], prix_ttc: 0, prix_ht: 0}
                                }else{
                                    list_prix_par_jour[date.toString()] = res_filter[0]
                                }
                            }
                        } else {
                            list_prix_par_jour[date.toString()] = null;
                        }
                    })

                    dispatch(changeListPrixParJour(list_prix_par_jour))


                    /**
                     * calculate total of days location
                     */
                    var total_ht = _.reduce(list_prix_par_jour, (sum , prix) => {
                        return sum + prix.prix_ht;
                    },0)
                    var total_ttc = _.reduce(list_prix_par_jour, (sum , prix) => {
                        return sum + prix.prix_ttc;
                    },0)
                    // console.log('total_ht', total_ht)
                    // console.log('total_ttc', total_ttc)
                    //
                    dispatch(changeTotalLocation({
                        total_ht,
                        total_ttc
                    }))
                }else{
                    ToastWarning("Aucun prix n'a été trouvé pour ce Modéle")
                }
            }
            
        }

        // setNombreJours(NumberOfDays == 0 ? 1 : NumberOfDays)




    }
    //reload the recalculateNbreJours() on every change for interval
    useEffect(() => {
        recalculateNbreJours()

    }, [date_debut, date_fin, heure_debut, heure_fin])


    /**
     * @desc recalculate the total of the selected optios by respecting the conditions of type_prix ["TOTAL" OR "PAR_JOUR"]
     */
    const recalculateTotalOptions = async () => {
        const selected_options = temp_reservation.listSelectedOptions;
        // console.log('selected_options', selected_options)
        const total_ttc =
            _.reduce(selected_options, (sum, option) => {

                // console.log(option.libelle, option.prix_ttc, option.type_prix, Number(option.nbr_selected))
                var line =  option.type_prix === "TOTAL" ?
                (Number(option.prix_ttc) * Number(option.nbr_selected)) :
                ((Number(option.prix_ttc) * Number(option.nbr_selected))) * temp_reservation.numberOfDays;

                return sum + line
            }, 0);

        const total_ht =
            _.reduce(selected_options, (sum, option) => {

                var line = option.type_prix === "TOTAL" ?
                (Number(option.prix_ht) * Number(option.nbr_selected)) :
                ((Number(option.prix_ht) * Number(option.nbr_selected))) * temp_reservation.numberOfDays;

                return sum + line
            }, 0);

        // setTotalOptions({ ...totalOptions, total_ht, total_ttc })
        //  console.log('changeTotalOptions', total_ht, total_ttc)
        dispatch(changeTotalOptions({
            total_ht,
            total_ttc
        }))

    }

    //reload recalculateTotalOptions when the selected_options is changed
    //and push it to redux
    useEffect(() => {
        // calc total
        recalculateTotalOptions();
        recalculateNbreJours()

    }, [temp_reservation.listSelectedOptions, temp_reservation.numberOfDays])


     /**
     * 
     * @param option OptionType
     * @param selected nbr selected when increment or decrement from button
     * @returns 
     */
     const findPrixCarburant = (option: OptionType, selected: number | null =null) => {

        if(carburant && carburant.length>0) {
            const selected_Carburant : CarburantType = carburant[0];
            const reservoir: number | null | undefined = selected_model.reservoir;
            const nbr_bar_selected =selected ? selected : temp_reservation.listSelectedOptions[option.id] ? temp_reservation.listSelectedOptions[option.id].nbr_selected : 0

            if(reservoir && nbr_bar_selected && nbr_bar_selected>0){
                const prix_ttc = (reservoir / option.nbr_max) * selected_Carburant.prix_ttc;
                const prix_ht = (reservoir / option.nbr_max) * selected_Carburant.prix_ht;
                return {
                    prix_ttc: prix_ttc.toFixed(3),
                    prix_ht: prix_ht.toFixed(3),
                }
            }else{
                return {
                    prix_ttc: 0,
                    prix_ht: 0,
                }
            }
        }else{
            return {
                    prix_ttc: 0,
                    prix_ht: 0,
                }
        }
    }

    /**
     * @desc decrement the number of selected option
     * @param option Object
     * @param nombre number
     */
    const decrementOption = async (option: OptionType) => {
        const id: number = option?.id as number;
        const quantite = temp_reservation.listSelectedOptions[id] ? Number(temp_reservation.listSelectedOptions[id].nbr_selected) : 0;

        // check if the quantiy is positive then decrement it by one , else got an zero
        const new_quantite = quantite > 0 ? quantite - 1 : 0;
        // setSelectedOption({ ...selected_options, [id]: { ...option, nbr_selected: new_quantite } })

        if(new_quantite==0){
            //remove object from list
            var list_select_option =  _.omit(temp_reservation.listSelectedOptions,id); 
            dispatch(changeListSelectedOptions({ ...list_select_option }))
        }else{
            if(option.type_option!=='CARBURAN'){
                dispatch(changeListSelectedOptions({ ...temp_reservation.listSelectedOptions, [id]: { ...option, nbr_selected: new_quantite } }))
            }else{
                const prix_carburant = findPrixCarburant(option,new_quantite)
                dispatch(changeListSelectedOptions({ 
                    ...temp_reservation.listSelectedOptions, 
                    [id]: { 
                        ...option, 
                        nbr_selected: new_quantite, 
                        prix_ttc: prix_carburant.prix_ttc, 
                        prix_ht: prix_carburant.prix_ht 
                    } 
                }))
            }
        }

    }

    /**
     * @desc incremet nombre of selected option
     * @param option Object
     * @param nombre number
     */
    const incrementOption = async (option: OptionType) => {

        const id: number = option?.id as number;
        const quantite = temp_reservation.listSelectedOptions[id] ? Number(temp_reservation.listSelectedOptions[id].nbr_selected) : 0;

        //check if the current quantite is less than nbr_max of  selected option
        if (quantite < option.nbr_max) {
            //increment quantite
            const new_quantite = quantite + 1;
            if(option.type_option!=='CARBURAN'){
                dispatch(changeListSelectedOptions({ ...temp_reservation.listSelectedOptions, [id]: { ...option, nbr_selected: new_quantite } }))
            }else{
                const prix_carburant = findPrixCarburant(option, new_quantite)
                // console.log('prix_carburant', prix_carburant)
                dispatch(changeListSelectedOptions({ 
                    ...temp_reservation.listSelectedOptions, 
                    [id]: { 
                        ...option, 
                        nbr_selected: new_quantite, 
                        prix_ttc: prix_carburant.prix_ttc, 
                        prix_ht: prix_carburant.prix_ht 
                    } 
                }))
            }
        }
    }

    /**
     * @desc Submit reservation form
     * @param data {ReservationDataType}
     */
    const onSubmit = async (data: ReservationDataType) => {

        const body = {
            total_options: temp_reservation.total_options,
            total_location: temp_reservation.total_location,
            selected_options: temp_reservation.listSelectedOptions,
            list_prix_par_jour: temp_reservation.list_prix_par_jour,
            nombre_jours: temp_reservation.numberOfDays,
            ...data
        }

        // console.log(body)

        const response = await dispatch(addReservationApi(body));
        // console.log("response.payload", response.payload);
        if (response.payload.success) {
            ToastSuccess(response.payload.message)
            InitalFormReservation();
        } else {
            ToastWarning(response.payload.message);
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3>
                    <span className="heading-color">Periode</span>
                </h3>
                {/* -------------------------------INTERVALE ------------------------ */}
                <div className="row">
                    <div className="col-md-2  col-sm-2 col-xs-7">
                        <div><label htmlFor="Date début" id="date_debut">Date Début</label></div>
                        <Controller
                            control={control}
                            name="date_debut"
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <ThemeProvider theme={theme}>
                                    <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            format="yyyy-MM-dd"
                                            closeOnSelect={true}
                                            minDate={new Date()}
                                            defaultValue={value ? value : null}
                                            value={value ? value : null}
                                            className={`${errors.date_debut ? 'is-invalid' : ''}`}

                                            onChange={(newValue: Date | null) => setValue('date_debut', newValue as Date)}
                                        />
                                    </LocalizationProvider>
                                </ThemeProvider>
                            )}
                        />
                        <div className="invalid-feedback">{errors.date_debut?.message}</div>
                    </div>

                    <div className="col-md-2 col-sm-2 col-xs-5">
                        <div><label htmlFor="Date début" >Heure Début</label></div>
                        <Controller
                            control={control}
                            name="heure_debut"
                            key="heure_debut"
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <ThemeProvider theme={theme}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileTimePicker
                                            label={'Heure début'}
                                            openTo="hours"
                                            views={['hours', 'minutes']}
                                            format="HH:mm"
                                            className={`${errors.heure_debut ? 'is-invalid' : ''}`}
                                            ampm={false}
                                            closeOnSelect={true}
                                            onChange={(newValue: Date | null) => setValue('heure_debut', newValue)}
                                        />
                                    </LocalizationProvider>
                                </ThemeProvider>
                            )}
                        />
                        <div className="invalid-feedback">{errors.heure_debut?.message}</div>
                    </div>

                    <div className="col-md-2 col-sm-2 col-xs-12">
                        <div className="form-group">
                            <label htmlFor="Lieu de prise en charge" className="form-check-label">Lieu de prise en charge </label>
                            <select className={`form-control ${errors.lieux_priseencharge_id ? 'is-invalid' : ''}`} id="lieux_priseencharge_id" {...register('lieux_priseencharge_id')}>
                                <option value="">Choisir...</option>
                                {
                                    lieux && lieux.map(element => (
                                    <option key={element.id} value={element.id}> 
                                        {element.name} 
                                        {/* - <b>{!_.isUndefined(element.Lieux_Parametrage_Prix) && element.Lieux_Parametrage_Prix.length>0 ? element.Lieux_Parametrage_Prix[0].prix_ttc_max : 0 } TND </b>  */}
                                    </option>))
                                }
                            </select>
                            <div className="invalid-feedback">{errors.lieux_priseencharge_id && errors.lieux_priseencharge_id.message && errors.lieux_priseencharge_id.message.toString()}</div>
                        </div>
                    </div>

                    <div className="col-md-2 col-sm-2 col-xs-7">
                        <div><label htmlFor="Date début"  id="date_fin">Date Fin</label></div>
                        <Controller
                            control={control}
                            name="date_fin"
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <ThemeProvider theme={theme}>
                                    <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            format="yyyy-MM-dd"
                                            closeOnSelect={true}
                                            minDate={new Date()}
                                            defaultValue={value ? value : null}
                                            value={value ? value : null}
                                            className={`${errors.date_fin ? 'is-invalid' : ''}`}
                                            onChange={(newValue: Date | null) => setValue('date_fin', newValue as Date)}
                                        />
                                    </LocalizationProvider>
                                </ThemeProvider>
                            )}
                        />
                        <div className="invalid-feedback">{errors.date_fin?.message}</div>

                    </div>
  
                    <div className="col-md-2 col-sm-2 col-xs-5">
                        <div><label htmlFor="Date début" >Heure Fin</label></div>
                        <input type='hidden' id="heure_fin" {...register('heure_fin')}/>
                        <Controller
                            control={control}
                            name="heure_fin"
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <ThemeProvider theme={theme} >
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileTimePicker
                                            label={'Heure fin'}
                                            openTo="hours"
                                            views={['hours', 'minutes']}
                                            format="HH:mm"
                                            className={`${errors.heure_fin ? 'is-invalid' : ''}`}
                                            ampm={false}
                                            closeOnSelect={true}
                                            onChange={(newValue: Date | null) => setValue('heure_fin', newValue)}                                            
                                        />
                                    </LocalizationProvider>
                                </ThemeProvider>
                            )}
                        />
                        <div className="invalid-feedback">{errors.heure_fin?.message}</div>

                    </div>

                    <div className="col-md-2 col-sm-2 col-xs-12">
                        <div className="form-group">
                            <label htmlFor="Lieut de restritution" className="form-check-label">Lieu de restitution </label>
                            <select className={`form-control ${errors.lieux_restitution_id ? 'is-invalid' : ''}`} id="lieux_restitution_id" {...register('lieux_restitution_id')}>
                                <option value="">Choisir...</option>
                                {
                                    lieux && lieux.map(element => (
                                        <option key={element.id} value={element.id}> 
                                            {element.name} 
                                            {/* -  <b>{!_.isUndefined(element.Lieux_Parametrage_Prix) && element.Lieux_Parametrage_Prix.length>0 ? element.Lieux_Parametrage_Prix[0].prix_ttc_max : 0 } TND </b> */}
                                        </option>)
                                    )
                                }
                            </select>
                            <div className="invalid-feedback">{errors.lieux_restitution_id && errors.lieux_restitution_id.message && errors.lieux_restitution_id.message.toString()}</div>
                        </div>
                    </div>
                </div>

                {/* ------------------------------- REMARQUE ------------------------ */}


                <div className="row">
                    <div className="col-md-12">
                        <label htmlFor="lieu" className="c-red">Remarque :</label>
                        <p>Les lieux Hors zone son négociable avec le responsable de location, accord préalable.</p>
                        <textarea className="form-control"  {...register('remarque')} id="remarque"
                        placeholder="Remarque..."></textarea>
                    </div>
                </div>


                {/* ------------------------------- OPTIONS ------------------------ */}

                {
                    !_.isEmpty(options) && (
                        <>
                            <h3>
                                <span className="heading-color">Les options</span>
                            </h3>
                            {
                                options?.map((option: OptionType, index: number) => (
                                    <div className="row option_list" key={index}  style={{ border: (temp_reservation.listSelectedOptions[option.id])  ? '1px solid #1abc9c' : 'none'}}>
                                        <div className="col-md-3 col-sm-6 col-xs-12 text-center">
                                            <img src={process.env.REACT_APP_API_PUBLIC_URL + 'options/' + option.image} alt={option.libelle} style={{ width: "50px" }} />
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xs-12 text-center">
                                            <h4 className='option_libelle'>{option.libelle}</h4>
                                        </div>
                                        <div className="col-md-2 col-sm-6 col-xs-12 text-center">

                                            {/* ------------------- OPTION HORS CARBURANT------------------- */}
                                            {
                                                option.type_option!=='CARBURAN' && 
                                                    (option.prix_ttc === 0 ? 'Gratuit' : option.prix_ttc + ' TND') + 
                                                    (option.type_prix=='PAR_JOUR' && option.prix_ttc !== 0 ? " / Jour" : '') 
                                                   
                                            }
                                            {/* ------------------- OPTION CARBURANT------------------- */}
                                            {
                                                option.type_option==='CARBURAN' &&
                                                ( 
                                                    <a onClick={e => setShowModalCarburant(true)}>
                                                        {findPrixCarburant(option, 1).prix_ttc + ' TND / Bar '}
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                    </a>
                                                )
                                            }
                                            
                                        </div>
                                        <div className="col-md-3 col-sm-6 col-xs-12 text-center" >
                                            {
                                                (
                                                    <div className="option_add text-center">
                                                        <button type="button" className="btn btn-xs btn-warning pull-left option_btn_minus"
                                                            onClick={e => decrementOption(option)}>
                                                            <i className="fa fa-minus"></i>
                                                        </button>
                                                        <div className="pull-left text-center option_input_add">
                                                            {temp_reservation.listSelectedOptions[option.id] ? temp_reservation.listSelectedOptions[option.id].nbr_selected : 0}
                                                            / {option.nbr_max}
                                                        </div>
                                                        <input type="hidden"
                                                            className="pull-left text-center option_input_add" disabled
                                                            name={`option_${option.id}`}
                                                            value={temp_reservation.listSelectedOptions[option.id] ? temp_reservation.listSelectedOptions[option.id].nbr_selected : 0}
                                                        />
                                                        <button type="button" className="btn btn-xs btn-warning pull-left option_btn_add"
                                                            onClick={e => incrementOption(option)}>
                                                            <i className="fa fa-plus"></i>
                                                        </button>
                                                    </div>
                                                )
                                            }


                                        </div>
                                    </div>
                                ))
                            }
                        </>
                    )
                }

                <div className="row" >
                    <div className="col-md-12">

                    </div>
                </div>

                <h3>
                    <span className="heading-color">Des informations sur le conducteur.</span>
                </h3>
                <div className="row">
                    <div className="col-md-2 col-sm-6 col-xs-12">
                        <label>Civilité <span className="color-red">*</span></label>
                        <select className={`form-control  ${errors.civilite ? 'is-invalid' : ''}`}
                            {...register('civilite')} id="civilite">
                            <option value="">Choisir...</option>
                            <option value="Monsieur">Monsieur</option>
                            <option value="Mme">Mme</option>
                            <option value="Mlle">Mlle</option>
                        </select>
                        <div className="invalid-feedback">{errors.civilite?.message}</div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <label>Nom <span className="color-red">*</span></label>
                        <input type="text"
                            className={`form-control  ${errors.nom ? 'is-invalid' : ''}`}
                            {...register('nom')} id="nom" />
                        <div className="invalid-feedback">{errors.nom?.message}</div>

                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <label>Prenom <span className="color-red">*</span></label>
                        <input type="text"
                            className={`form-control  ${errors.prenom ? 'is-invalid' : ''}`}
                            {...register('prenom')} id="prenom" />
                        <div className="invalid-feedback">{errors.prenom?.message}</div>

                    </div>
                    <div className="col-md-2 col-sm-6 col-xs-12">
                        <label>Mobile <span className="color-red">*</span></label>
                        <Controller
                            control={control}
                            name="mobile"
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <IntlTelInput
                                    containerClassName="intl-tel-input"
                                    inputClassName={`form-control  ${errors.mobile?.status ? 'is-invalid' : ''}`}
                                    style={{ width: '100%' }}
                                    onPhoneNumberChange={handlePhoneChange}
                                    defaultCountry={user.iso2_mobile ? user.iso2_mobile : 'tn'}
                                    preferredCountries={['tn', 'fr', 'ma', 'dz']}
                                    defaultValue={user.mobile ? user.mobile : ''}
                                    fieldId='mobile'
                                />
                            )}
                        />
                        <div className=""></div>
                        <div className="invalid-feedback">{errors.mobile?.status?.message}</div>
                    </div>
                    <div className="col-md-2 col-sm-6 col-xs-12">
                        <label>Téléphone</label>
                        <Controller
                            control={control}
                            name="telephone"
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <IntlTelInput
                                    containerClassName="intl-tel-input"
                                    inputClassName={`form-control  ${errors.telephone?.status ? 'is-invalid' : ''}`}
                                    style={{ width: '100%' }}
                                    onPhoneNumberChange={handlePhoneChange}
                                    defaultCountry={user.iso2_telephone ? user.iso2_telephone : 'tn'}
                                    preferredCountries={['tn', 'fr', 'ma', 'dz']}
                                    defaultValue={user.telephone ? user.telephone : ''}
                                    fieldId='telephone'
                                />
                            )}
                        />
                        <div className=""></div>
                        <div className="invalid-feedback">{errors.telephone?.status?.message}</div>
                    </div>
                </div>
                <div className="row">
                <div className="col-md-2 col-sm-6 col-xs-12">
                        <label>Adresse </label>
                        <input type="text"
                            className={`form-control ${errors.adresse ? 'is-invalid' : ''}`}
                            {...register('adresse')} id="adresse" />
                        <div className="invalid-feedback">{errors.adresse?.message}</div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <label>Email <span className="color-red">*</span></label>
                        <input type="text"
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            {...register('email')} id="email" />
                        <div className="invalid-feedback">{errors.email?.message}</div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <label>Age <span className="color-red">*</span></label>
                        <input type="number"
                            className={`form-control ${errors.age ? 'is-invalid' : ''}`}
                            {...register('age')} id="age" />
                        <div className="invalid-feedback">{errors.age?.message}</div>

                    </div>
                    <div className="col-md-2 col-sm-6 col-xs-12">
                        <label id="date_permis" >Date permis <span className="color-red">*</span></label>
                        <Controller
                            control={control}
                            name="date_permis"
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <ThemeProvider theme={theme}>
                                    <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            format="yyyy-MM-dd"
                                            closeOnSelect={true}
                                            defaultValue={user.date_permis ? new Date(user.date_permis) : null}
                                            value={user.date_permis ? new Date(user.date_permis) : null}

                                            className={`${errors.date_permis ? 'is-invalid' : ''}`}
                                            onChange={(newValue: Date | null) => setValue('date_permis', newValue as Date | null)}
                                        />
                                    </LocalizationProvider>
                                </ThemeProvider>
                            )}
                        />
                        <div className="invalid-feedback">{errors.date_permis?.message}</div>
                    </div>
                    
                </div>
                <h3>
                    <span className="heading-color">Avez-vous un vol le jour de la prise de véhicule ?</span>
                </h3>
                <div className="row">
                <div className="col-md-1 col-sm-2 col-xs-12">
                        <div className="form-group">
                            <div className="skin-minimal">
                                <ul className="list">
                                    <li>
                                        <input type="checkbox" id="vol"
                                            className={` ${errors.vol ? 'is-invalid' : ''}`}
                                            {...register('vol')} />
                                        <label htmlFor="vol">Oui</label>
                                        <div className="invalid-feedback">{errors.vol?.message}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {
                        vol && (
                            <div className="col-md-11 col-sm-10 col-xs-12">
                                <div className="form-group app-space-around">
                                    <label htmlFor="numero_vol" className="pull-left">Numero de Vol : <span className="c-red">*</span></label>
                                    <div className="skin-minimal w-80" >
                                        <ul className="list">
                                            <li>
                                                <input type="text" id="numero_vol"
                                                    className={`form-control ${errors.numero_vol ? 'is-invalid' : ''}`}
                                                    {...register('numero_vol')} />
                                                <div className="invalid-feedback">{errors.numero_vol?.message}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <h3>
                    <span className="heading-color">Les conditions d'utilisation</span>
                </h3>
                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12">
                        <div className="form-group">
                            <div className="skin-minimal">
                                <ul className="list">
                                    <li>
                                        <input type="checkbox" id="condition"
                                            className={` ${errors.condition ? 'is-invalid' : ''}`}
                                            {...register('condition')} />
                                        <label htmlFor="condition">J'accepte <a href="#">les conditions d'utilisation</a></label>
                                        <div className="invalid-feedback">{errors.condition?.message}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12 text-right">
                        <button type="submit" className="btn btn-theme btn-sm">Envoyer</button>
                    </div>
                </div>
            </form>


            { options && <ReservationCarburant modele={selected_model} 
                                  carburant={carburant} 
                                  options={options} 
                                  ShowModal={ShowModalCarburant}
                                  SetShowModal={setShowModalCarburant} />}
        </>
    )
}

export default ReservationForm